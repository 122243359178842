import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { useUserId } from "shared/stores/oidc";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";

export function useAddUserAddress() {
    const { userId } = useUserId();
    const { mutateAsync: addUserAddressAsync } = useGraphqlMutation({
        document: mutations.addAddress,
        onSuccess: async () => {
            await qk.invalidate("user", "id", userId);
            notify.show.success({ message: "Added new address" });
        },
    });
    return { addUserAddressAsync };
}

export function useEditUserAddress() {
    const { userId } = useUserId();
    const { mutateAsync: editUserAddressAsync } = useGraphqlMutation({
        document: mutations.editAddress,
        onSuccess: async () => {
            await qk.invalidate("user", "id", userId);
            notify.show.success({ message: "Edited location" });
        },
    });
    return { editUserAddressAsync };
}

export function useRemoveUserAddress() {
    const { userId } = useUserId();
    const { mutateAsync: removeUserAddressAsync } = useGraphqlMutation({
        document: mutations.removeAddress,
        onSuccess: async () => {
            await qk.invalidate("user", "id", userId);
            notify.show.success({ message: "Removed location" });
        },
    });
    return { removeUserAddressAsync };
}

export function useAddEud() {
    const { mutateAsync: addEudAsync } = useGraphqlMutation({
        document: mutations.addEud,
        onSuccess: async () => {
            await qk.invalidate("user");
        },
    });
    return { addEudAsync };
}

export function useVerifyEud() {
    const { mutateAsync: verifyEudAsync } = useGraphqlMutation({
        document: mutations.verifyEud,
        onSuccess: async () => {
            await qk.invalidate("user");
            notify.show.success({ message: "Device verified" });
        },
    });
    return { verifyEudAsync };
}

export function useResendVerifyEud() {
    const { mutate: resendVerifyEud } = useGraphqlMutation({
        document: mutations.resendEud,
        onSuccess: () => notify.show.success({ message: "Resent verification code" }),
    });
    return { resendVerifyEud };
}

export function useRemoveEud() {
    const { mutate: removeEud } = useGraphqlMutation({
        document: mutations.removeEud,
        onSuccess: async () => {
            await qk.invalidate("user");
            notify.show.success({ message: "Removed device" });
        },
    });
    return { removeEud };
}

export function useUpdateUser() {
    const { mutate: updateUser } = useGraphqlMutation({
        document: mutations.updateUser,
        onSuccess: async () => {
            await qk.invalidate("user");
            notify.show.success({ message: "Updated name" });
        },
        onError: () => notify.show.error({ message: "Error updating name" }),
    });
    return { updateUser };
}

const mutations = {
    updateUser: graphql(`
        mutation UpdateUser($userId: UUID!, $firstName: String!, $lastName: String!, $language: Language!) {
            updateUser(userId: $userId, params: { firstName: $firstName, lastName: $lastName, language: $language }) {
                id
            }
        }
    `),
    addAddress: graphql(`
        mutation AddUserAddress($userId: UUID!, $address: UserAddressInput!) {
            addUserAddress(userId: $userId, address: $address) {
                id
            }
        }
    `),
    editAddress: graphql(`
        mutation EditUserAddress($userId: UUID!, $addressId: UUID!, $address: UserAddressInput!) {
            updateUserAddress(userId: $userId, addressId: $addressId, address: $address) {
                id
            }
        }
    `),
    removeAddress: graphql(`
        mutation RemoveUserAddress($userId: UUID!, $addressId: UUID!) {
            removeUserAddress(userId: $userId, addressId: $addressId)
        }
    `),
    addEud: graphql(`
        mutation AddEud($userId: UUID!, $params: EndUserDeviceParameterInput!) {
            addEUD(userId: $userId, eudParams: $params) {
                id
                value
            }
        }
    `),
    verifyEud: graphql(`
        mutation VerifyEud($userId: UUID!, $eudId: UUID!, $code: String!) {
            verifyEUD(userId: $userId, eudId: $eudId, verificationCode: $code) {
                id
            }
        }
    `),
    resendEud: graphql(`
        mutation ResendEud($userId: UUID!, $eudID: UUID!) {
            resendEUDVerificationCode(userId: $userId, eudId: $eudID) {
                id
            }
        }
    `),
    removeEud: graphql(`
        mutation RemoveEud($userId: UUID!, $eudId: UUID!) {
            removeEUD(userId: $userId, eudId: $eudId)
        }
    `),
};
