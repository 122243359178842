import { Title } from "@mantine/core";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, Outlet } from "@tanstack/react-router";
import { Content } from "shared/components/global/Content";
import {
    GroupNode,
    groupsQueryOptions,
    sharedOrgPageGroupDisabledCheck,
    SharedOrgPageGroupLabel,
} from "shared/components/organization/groups";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { usePermissions } from "shared/stores/oidc";

const Page = () => {
    const isMobile = useIsMobile();
    const {
        data: { rootGroup },
    } = useSuspenseQuery(groupsQueryOptions.rootGroup);
    const permissions = usePermissions();

    return isMobile ? (
        <Content>
            <Content.Heading>
                <Title order={2}>Groups</Title>
            </Content.Heading>
            <GroupNode
                group={rootGroup}
                isDisabledPredicate={(g) => sharedOrgPageGroupDisabledCheck(permissions, g)}
                renderLabel={SharedOrgPageGroupLabel}
                initialExpand
            />
        </Content>
    ) : (
        <Outlet />
    );
};

/** @public */
export const Route = createFileRoute("/_auth/organization/groups/")({
    component: Page,
});
