import { createFileRoute } from "@tanstack/react-router";
import { Content } from "shared/components/global/Content";
import { groupsQueryOptions } from "shared/components/organization/groups";
import { topicsQueryOptions } from "shared/components/organization/topics";
import { useUserGroups } from "shared/components/organization/users";
import { GroupsContainer } from "shared/components/settings/groups";
import { LocationsContainer } from "shared/components/settings/locations";
import { languageQueryOptions, ProfileContainer } from "shared/components/settings/profile";
import { TopicsContainer } from "shared/components/settings/topics";
import { logger } from "shared/utils/logger";
import { queryClient } from "src/queryClient";

const Page = () => {
    const { groups } = useUserGroups();

    return (
        <Content>
            <ProfileContainer />
            <LocationsContainer />
            <TopicsContainer />
            {groups.length > 0 && <GroupsContainer />}
        </Content>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/settings")({
    component: Page,
    loader: () => {
        queryClient.ensureQueryData(languageQueryOptions).catch(logger.error);
        queryClient.ensureQueryData(topicsQueryOptions.rootTopic).catch(logger.error);
        queryClient.ensureQueryData(groupsQueryOptions.rootGroup).catch(logger.error);
    },
});
