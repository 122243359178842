import {
    ActionIcon,
    Box,
    Checkbox,
    Combobox,
    Group,
    Input,
    InputBase,
    Loader,
    LoadingOverlay,
    Stack,
    Text,
    Tooltip,
    useCombobox,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronRight } from "@tabler/icons-react";
import { queryOptions, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { Suspense, useState } from "react";
import { httpPostGraphql } from "shared/api/httpClient";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { useUserId } from "shared/stores/oidc";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";
import { TopicLikeFragment } from "src/gql/graphql";

export const TopicNode = ({
    topic,
    initialExpandedNodes,
    initialCheckedTopicIds,
    shouldRenderCheckbox,
    checkboxOnChange,
    disabledPredicate,
    disabledTooltipLabel,
}: {
    topic: TopicLikeFragment;
    initialExpandedNodes: string[];
    initialCheckedTopicIds: string[];
    shouldRenderCheckbox: (topic: TopicLikeFragment) => boolean;
    checkboxOnChange: (id: string, checked: boolean) => void;
    disabledPredicate?: (topic: TopicLikeFragment) => boolean;
    disabledTooltipLabel?: string;
}) => {
    const [opened, { toggle }] = useDisclosure(initialExpandedNodes.includes(topic.id));
    const { data, isLoading } = useSuspenseQuery({
        queryKey: qk("topic", "id", topic.id, "children"),
        queryFn: () => httpPostGraphql(topicsQueries.topicChildrenQuery, { id: topic.id }),
    });

    const children = data?.topic.children as TopicLikeFragment[] | undefined;
    return (
        <Box pos="relative">
            <LoadingOverlay visible={isLoading} />
            <Stack gap={0} style={{ display: "inline-flex" }} w="min-content">
                <Group gap="0" w="min-content" p="sm" wrap="nowrap" style={{ whiteSpace: "nowrap" }}>
                    <ActionIcon
                        display={topic.isCategory && (children?.length ?? 0) > 0 ? undefined : "none"}
                        variant="transparent"
                        color="text"
                        onClick={toggle}
                    >
                        <IconChevronRight
                            size={16}
                            style={{ transition: "transform 0.5s", transform: opened ? "rotate(90deg)" : "rotate(0deg)" }}
                        />
                    </ActionIcon>
                    {shouldRenderCheckbox(topic) && (
                        <Tooltip disabled={!disabledPredicate?.(topic)} label={disabledTooltipLabel}>
                            <Checkbox
                                disabled={disabledPredicate?.(topic) || topic.isRequired}
                                defaultChecked={initialCheckedTopicIds.includes(topic.id) || topic.isRequired}
                                onChange={(e) => checkboxOnChange(topic.id, e.target.checked)}
                                pr="sm"
                            />
                        </Tooltip>
                    )}
                    <Text>{topic.name}</Text>
                </Group>
                <Stack gap={0} style={{ display: opened ? undefined : "none" }}>
                    <Box
                        w="fit-content"
                        style={{
                            borderLeft: "3rem solid transparent",
                            overflow: "auto",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        {children?.map((c) => (
                            <TopicNode
                                key={c.id}
                                initialExpandedNodes={initialExpandedNodes}
                                topic={c}
                                shouldRenderCheckbox={shouldRenderCheckbox}
                                checkboxOnChange={checkboxOnChange}
                                initialCheckedTopicIds={initialCheckedTopicIds}
                                disabledPredicate={disabledPredicate}
                                disabledTooltipLabel={disabledTooltipLabel}
                            />
                        ))}
                    </Box>
                </Stack>
            </Stack>
        </Box>
    );
};

export const CategoryParentSelect = ({ parentId, setParentId }: { parentId: string; setParentId: (id: string) => void }) => {
    const combobox = useCombobox();
    const [showTopicTree, setShowTopicTree] = useState(false);

    const {
        data: { rootTopic },
    } = useSuspenseQuery(topicsQueryOptions.rootTopic);
    const { data, isLoading } = useQuery({ ...topicsQueryOptions.topic(parentId) });

    const TopicModal = () => {
        return (
            <Stack gap={0}>
                {rootTopic.children.map((t) => (
                    <TopicNode
                        key={t.id}
                        topic={t}
                        initialExpandedNodes={rootTopic.children.map((t) => t.id)}
                        initialCheckedTopicIds={[]}
                        shouldRenderCheckbox={(topic) => topic.isCategory}
                        checkboxOnChange={(id) => {
                            setParentId(id);
                            setShowTopicTree(false);
                        }}
                    />
                ))}
            </Stack>
        );
    };

    return (
        <>
            <Combobox store={combobox}>
                <Combobox.Target>
                    <InputBase
                        component="button"
                        type="button"
                        pointer
                        rightSection={<Combobox.Chevron />}
                        onClick={() => setShowTopicTree(true)}
                        rightSectionPointerEvents="none"
                        label="Parent Category"
                        withAsterisk
                    >
                        {data?.topic.name || (isLoading ? <Loader /> : <Input.Placeholder>Select parent...</Input.Placeholder>)}
                    </InputBase>
                </Combobox.Target>
            </Combobox>
            {showTopicTree && (
                <Stack mah={300} style={{ overflowY: "auto" }}>
                    <Suspense fallback={<Loader />}>
                        <TopicModal />
                    </Suspense>
                </Stack>
            )}
        </>
    );
};

export function useCreateTopic() {
    const nav = useNavigate();
    const { mutate: createTopic } = useGraphqlMutation({
        document: mutations.createTopic,
        onSuccess: async (data) => {
            const parentId = data?.createTopic.parent?.id;
            await Promise.all([parentId && (await qk.invalidate("topic", "id", parentId, "children"))]).then(() =>
                nav({ to: "/organization/topics" }),
            );
            notify.show.success({ message: "Topic created successfully" });
        },
    });
    return { createTopic };
}

export function useCreateCategory() {
    const { mutate: createCategory } = useGraphqlMutation({
        document: mutations.createCategory,
        onSuccess: async (data) => {
            const parentId = data?.createTopic.parent?.id;
            await Promise.all([qk.invalidate("user"), !!parentId && qk.invalidate("topic", "id", parentId)]);
            notify.show.success({ message: "Category created successfully" });
        },
    });
    return { createCategory };
}

export function useUpdateTopic() {
    const { mutateAsync: updateTopicAsync } = useGraphqlMutation({
        document: mutations.topicUpdate,
        onSuccess: async (data) => {
            const topicId = data?.updateTopic.id;
            topicId && (await qk.invalidate("topic", "id", topicId, "details"));
        },
    });
    return { updateTopicAsync };
}

export function useUpdateTopicParent() {
    const { mutateAsync: updateTopicParentAsync } = useGraphqlMutation({
        document: mutations.topicParentUpdate,
        onSuccess: async () => {
            await Promise.all([qk.invalidate("topic"), qk.invalidate("user")]);
        },
    });
    return { updateTopicParentAsync };
}

export function useAddUserToTopic() {
    const { userId } = useUserId();
    const { mutate: addUser } = useGraphqlMutation({
        document: mutations.addUser,
        onSuccess: async (data) => {
            const topicId = data?.addUserToTopic.id;
            await Promise.all([!!topicId && qk.invalidate("topic", "id", topicId, "members"), qk.invalidate("user", "id", userId)]);
            notify.show.success({ message: "Subscribed to topic" });
        },
    });
    return { addUser };
}

export function useRemoveUserFromTopic() {
    const { userId } = useUserId();
    const { mutate: removeUser } = useGraphqlMutation({
        document: mutations.removeUser,
        onSuccess: async (data) => {
            const topicId = data?.removeUserFromTopic.id;
            notify.show.success({ message: "Unsubscribed from topic" });
            await Promise.all([!!topicId && qk.invalidate("topic", "id", topicId, "members"), qk.invalidate("user", "id", userId)]);
        },
    });
    return { removeUser };
}

const topicsQueries = {
    topicFragment: graphql(`
        fragment TopicLike on TopicNode {
            isCategory
            isRequired
            id
            name
            description
        }
    `),
    rootTopicQuery: graphql(`
        query RootTopic {
            rootTopic {
                ...TopicLike
                children {
                    ...TopicLike
                }
            }
        }
    `),
    topicQuery: graphql(`
        query Topic($id: UUID!) {
            topic(topicId: $id) {
                ...TopicLike
                children {
                    id
                }
                parent {
                    id
                    name
                }
            }
        }
    `),
    topicChildrenQuery: graphql(`
        query TopicChildren($id: UUID!) {
            topic(topicId: $id) {
                children {
                    ...TopicLike
                }
            }
        }
    `),
    topicMembersQuery: graphql(`
        query TopicMembers($id: UUID!) {
            topic(topicId: $id) {
                members {
                    id
                }
            }
        }
    `),
};

export const topicsQueryOptions = {
    rootTopic: queryOptions({
        queryFn: () => httpPostGraphql(topicsQueries.rootTopicQuery, {}),
        queryKey: qk("topic", "rootTopic"),
        select: ({ rootTopic }) => {
            const { children, ...rest } = rootTopic;
            return {
                rootTopic: {
                    children: children as TopicLikeFragment[],
                    ...(rest as TopicLikeFragment),
                },
            };
        },
    }),
    topic: (id: string) =>
        queryOptions({
            queryFn: () => httpPostGraphql(topicsQueries.topicQuery, { id }),
            queryKey: qk("topic", "id", id, "details"),
            select: ({ topic }) => {
                const { children, parent, ...rest } = topic;
                return {
                    topic: {
                        children: children as TopicLikeFragment[],
                        parent,
                        ...(rest as TopicLikeFragment),
                    },
                };
            },
        }),
    topicChildren: (id: string) =>
        queryOptions({
            queryFn: () => httpPostGraphql(topicsQueries.topicChildrenQuery, { id }),
            queryKey: qk("topic", "id", id, "children"),
            select: ({ topic }) => ({ children: topic.children as TopicLikeFragment[] }),
        }),
    topicMembers: (id: string) =>
        queryOptions({
            queryFn: () => httpPostGraphql(topicsQueries.topicMembersQuery, { id }),
            queryKey: qk("topic", "id", id, "members"),
            select: ({ topic }) => ({ members: topic.members }),
        }),
};

const mutations = {
    topicUpdate: graphql(`
        mutation UpdateTopic($topicId: UUID!, $params: UpdateTopicParametersInput!) {
            updateTopic(id: $topicId, params: $params) {
                id
            }
        }
    `),
    topicParentUpdate: graphql(`
        mutation UpdateTopicParent($topicId: UUID!, $parentId: UUID!) {
            changeTopicParent(id: $topicId, parentId: $parentId) {
                id
            }
        }
    `),
    createTopic: graphql(`
        mutation CreateTopic($params: CreateTopicParametersInput!) {
            createTopic(params: $params) {
                parent {
                    id
                }
            }
        }
    `),
    createCategory: graphql(`
        mutation CreateCategory($title: String!, $parent: UUID!) {
            createTopic(params: { name: $title, isCategory: true, isRequired: false, parentId: $parent }) {
                parent {
                    id
                }
            }
        }
    `),
    addUser: graphql(`
        mutation AddUserToTopic($topicId: UUID!, $userId: UUID!) {
            addUserToTopic(topicId: $topicId, userId: $userId) {
                id
            }
        }
    `),
    removeUser: graphql(`
        mutation RemoveUserFromTopic($topicId: UUID!, $userId: UUID!) {
            removeUserFromTopic(topicId: $topicId, userId: $userId) {
                id
            }
        }
    `),
};
