import "routes/_auth/organization/groups.css";

import { ActionIcon, Group, Stack, Title } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, Outlet, useNavigate } from "@tanstack/react-router";
import {
    GroupNode,
    groupsQueryOptions,
    sharedOrgPageGroupDisabledCheck,
    SharedOrgPageGroupLabel,
} from "shared/components/organization/groups";
import { infiniteUserQueryOptions } from "shared/components/organization/users";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { usePermissions } from "shared/stores/oidc";
import { logger } from "shared/utils/logger";
import { queryClient } from "src/queryClient";

const GroupsComponent = () => {
    const nav = useNavigate();
    const isMobile = useIsMobile();
    const permissions = usePermissions();
    const {
        data: { rootGroup },
    } = useSuspenseQuery(groupsQueryOptions.rootGroup);

    const DesktopView = () => {
        return (
            <Stack gap={0} h="100%">
                <Group w="100%" h="7dvh" p="xs" style={{ borderBottom: "1px solid var(--app-shell-border-color)" }}>
                    <Title order={2} fw="normal">
                        Groups
                    </Title>
                    <ActionIcon onClick={() => void nav({ to: "/organization/groups/create" })} variant="transparent">
                        <IconPlus size={16} />
                    </ActionIcon>
                </Group>
                <Group gap={0} wrap="nowrap" style={{ flexGrow: 1, overflowY: "auto" }}>
                    <Stack h="100%" mah="85dvh" w="16rem" style={{ borderRight: "1px solid var(--app-shell-border-color)" }}>
                        <Stack style={{ overflow: "auto", gap: 0, flexGrow: 1 }}>
                            <GroupNode
                                group={rootGroup}
                                isDisabledPredicate={(g) => sharedOrgPageGroupDisabledCheck(permissions, g)}
                                renderLabel={SharedOrgPageGroupLabel}
                                initialExpand
                            />
                        </Stack>
                    </Stack>
                    <Outlet />
                </Group>
            </Stack>
        );
    };

    return isMobile ? <Outlet /> : <DesktopView />;
};

/**
 * @public
 */
export const Route = createFileRoute("/_auth/organization/groups")({
    component: GroupsComponent,
    loader: () => {
        queryClient.prefetchInfiniteQuery(infiniteUserQueryOptions).catch(logger.error);
        queryClient.ensureQueryData(groupsQueryOptions.rootGroup).catch(logger.error);
    },
});
