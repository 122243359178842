import { ActionIcon, Button, Divider, Group, Stack, Title } from "@mantine/core";
import { IconPlus, IconX } from "@tabler/icons-react";
import { Content } from "shared/components/global/Content";
import { GroupTreeCombobox, useAddUserToGroup, useRemoveUserFromGroup } from "shared/components/organization/groups";
import { useUserGroups } from "shared/components/organization/users";
import { useUserId } from "shared/stores/oidc";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { GroupLikeFragment } from "src/gql/graphql";

const GroupsList = () => {
    const { userId } = useUserId();
    const { groups } = useUserGroups();
    const { removeUser } = useRemoveUserFromGroup();

    return groups.map((g) => (
        <Stack key={g.id}>
            <Group justify="space-between">
                <Title order={3} pl="sm">
                    {g.name}
                </Title>
                {g.internalJoinable && (
                    <ActionIcon variant="transparent" onClick={() => removeUser({ userId, groupId: g.id })}>
                        <IconX size={16} />
                    </ActionIcon>
                )}
            </Group>
            <Divider />
        </Stack>
    ));
};

const JoinGroupsContainer = () => {
    const { userId } = useUserId();
    const { addUserAsync } = useAddUserToGroup();
    const { groups } = useUserGroups();

    return (
        <GroupTreeCombobox
            initialSelectedGroups={groups}
            onChange={async (groups) => {
                await Promise.all(groups.map((g) => addUserAsync({ userId, groupId: g.id })))
                    .then((data) => {
                        notify.show.success({ message: data.length > 1 ? "Successfully joined groups" : "Successfully joined group" });
                        return data;
                    })
                    .then((data) => {
                        const groups = data.map((g) => g?.addUserToGroup as GroupLikeFragment | undefined).filter((g) => !!g);
                        return Promise.all([
                            qk.invalidate("user", "id", userId),
                            ...groups.map((g) => qk.invalidate("group", "id", g.id, "members")),
                        ]);
                    });
            }}
            target={(onClick) => (
                <Button
                    variant="default"
                    leftSection={<IconPlus size={16} />}
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        onClick();
                    }}
                >
                    Join Groups
                </Button>
            )}
            shouldRenderCheckbox={(group) => group.internalJoinable}
            disabledPredicate={(g) => g.synchronized}
        />
    );
};

export const GroupsContainer = () => {
    return (
        <Content paper>
            <Content.Heading>
                <Title order={3} id="groups">
                    Groups
                </Title>
            </Content.Heading>

            <GroupsList />
            <JoinGroupsContainer />
        </Content>
    );
};
