import { ActionIcon, Blockquote, Group, Text, Title, Tooltip, TypographyStylesProvider } from "@mantine/core";
import { Link } from "@mantine/tiptap";
import { IconDownload, IconExclamationCircle } from "@tabler/icons-react";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useParams } from "@tanstack/react-router";
import { generateHTML } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import { httpPostGraphql } from "shared/api/httpClient";
import { Content } from "shared/components/global/Content";
import { FileItem } from "shared/components/global/FileItem";
import { MessageStatusBadge } from "shared/components/message/MessageStatusBadge";
import { catching } from "shared/utils/fns";
import { titlecase } from "shared/utils/fns";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";
import { queryClient } from "src/queryClient";

const Component = () => {
    const { id } = useParams({ from: "/_auth/messages/$id" });
    const {
        data: { message },
    } = useSuspenseQuery(query(id));

    return (
        <Content paper p="sm">
            <Content.Heading backable wrap="nowrap">
                <Title order={3} style={{ overflowY: "clip", overflowX: "auto", textOverflow: "ellipsis" }}>
                    {message.subject}
                </Title>
            </Content.Heading>

            <div>
                <Group justify="space-between">
                    <Title order={3}>{message.subject}</Title>
                    <MessageStatusBadge status={message.status} />
                </Group>
                <Group justify="space-between">
                    <Text>{`Sent: ${new Date(+message.publishedAt!).toLocaleString()}`}</Text>
                    <Text>{titlecase(message.severity)}</Text>
                </Group>
                <Text>{message.sender.name}</Text>
            </div>

            <article style={{ wordBreak: "break-word" }}>
                {catching(() => (
                    <TypographyStylesProvider>
                        <div
                            dangerouslySetInnerHTML={{ __html: generateHTML(JSON.parse(message.richBody) as never, [StarterKit, Link]) }}
                        />
                    </TypographyStylesProvider>
                )) ?? (
                    <Blockquote
                        m="md"
                        icon={
                            <Tooltip label="Viewing raw message content">
                                <IconExclamationCircle size={16} />
                            </Tooltip>
                        }
                        variant="warning"
                        cite="Failed to parse message body"
                    >
                        {message.richBody}
                    </Blockquote>
                )}
            </article>

            <Group>
                {message.files.map(({ id, preSigned }) => (
                    <FileItem fileId={id} key={id}>
                        <FileItem.Action>
                            <ActionIcon
                                component="a"
                                variant="transparent"
                                size="xs"
                                href={`${import.meta.env["VITE_API_BASE_URL"]}/presignedUrl/${preSigned.id}/download`}
                            >
                                <IconDownload size={16} />
                            </ActionIcon>
                        </FileItem.Action>
                    </FileItem>
                ))}
            </Group>
        </Content>
    );
};

const query = (id: string) =>
    queryOptions({
        queryKey: qk("messages", "detail", id),
        queryFn: () =>
            httpPostGraphql(
                graphql(`
                    query MessageDetail($id: UUID!) {
                        message(id: $id) {
                            id
                            subject
                            richBody
                            status
                            sender {
                                name
                            }
                            publishedAt
                            files {
                                id
                                preSigned {
                                    id
                                }
                            }
                            severity
                        }
                    }
                `),
                { id },
            ),
    });

/** @public */
export const Route = createFileRoute("/_auth/messages/$id")({
    component: Component,
    loader: ({ params: { id } }) => queryClient.ensureQueryData(query(id)),
});
