import { Box, Button, Center, Container, Paper, Title } from "@mantine/core";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { useAuth } from "react-oidc-context";
import { BryxLogo } from "src/assets/BryxLogo";
import { z } from "zod";

const fallback = "/messages" as const;

const LoginComponent = () => {
    const auth = useAuth();

    return (
        <Center h="100dvh">
            <Container size={420} my={40}>
                <Box w={210} h={210} m="auto" mb="md">
                    <BryxLogo />
                </Box>
                <Title ta="center">Welcome back!</Title>
                <Paper withBorder shadow="md" p="lg" mt="lg" radius="md">
                    <Button w="100%" onClick={() => void auth.signinRedirect()}>
                        Continue to Login
                    </Button>
                </Paper>
            </Container>
        </Center>
    );
};

/** @public */
export const Route = createFileRoute("/login")({
    validateSearch: z.object({
        redirect: z.string().optional().catch(""),
    }),
    beforeLoad: async ({ context, search }) => {
        const auth = await context.auth;
        if (auth.isAuthenticated) throw redirect({ to: search.redirect || fallback });
    },
    component: LoginComponent,
});
