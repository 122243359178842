import { Badge, Button, Group, Stack, Textarea, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconDownload, IconX } from "@tabler/icons-react";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { Content } from "shared/components/global/Content";
import {
    CategoryParentSelect,
    topicsQueryOptions,
    useCreateTopic,
    useUpdateTopic,
    useUpdateTopicParent,
} from "shared/components/organization/topics";
import { permissionCheck, usePermissions } from "shared/stores/oidc";
import { logger } from "shared/utils/logger";
import { maybe } from "shared/utils/maybe";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { Permission, TopicLikeFragment } from "src/gql/graphql";
import { queryClient } from "src/queryClient";
import { z } from "zod";

/** @private */
type FormType = {
    parent: string;
    name: string;
    description: string;
    required: boolean;
};

const TopicForm = ({
    topic,
    parentId,
    onSubmit,
    isCreateForm,
}: {
    topic: TopicLikeFragment;
    parentId: string;
    onSubmit: (data: FormType) => void;
    isCreateForm?: boolean;
}) => {
    const nav = useNavigate();
    const { required, category } = Route.useSearch();
    const permissions = usePermissions();
    const canViewTopicMembers = !isCreateForm && permissionCheck(permissions, Permission.TopicUsersView, topic.id);

    const { data: maybeTopicMembers } = useQuery({ ...topicsQueryOptions.topicMembers(topic.id), enabled: canViewTopicMembers });

    const form = useForm<FormType>({
        initialValues: {
            parent: category ?? parentId,
            name: topic.name,
            description: topic.description ?? "",
            required: !!required,
        },
    });

    useEffect(() => {
        form.resetDirty();
    }, [topic]);

    return (
        <form style={{ height: "100%" }} onSubmit={form.onSubmit(onSubmit)}>
            <Stack>
                <CategoryParentSelect parentId={parentId} setParentId={(id) => form.setValues({ parent: id })} />
                <TextInput label="Title" withAsterisk placeholder="Enter a title..." {...form.getInputProps("name")} />
                {!topic.isCategory && (
                    <>
                        <Textarea label="Description" placeholder="Enter a description..." {...form.getInputProps("description")} />
                        {maybe(maybeTopicMembers?.members)?.take((it) => <Title order={3}>{it.length} Total Subscribers</Title>)}
                    </>
                )}
                <Group justify="flex-end">
                    <Button leftSection={<IconDownload size={16} />} type="submit" disabled={!form.isDirty()}>
                        Save
                    </Button>
                    <Button
                        variant="default"
                        leftSection={<IconX size={16} />}
                        onClick={(e) => {
                            e.preventDefault();
                            void nav({ to: "/organization/topics" });
                        }}
                    >
                        Cancel
                    </Button>
                </Group>
            </Stack>
        </form>
    );
};

const TopicCreateContainer = () => {
    const { required } = Route.useSearch();
    const { createTopic } = useCreateTopic();
    const {
        data: { rootTopic },
    } = useSuspenseQuery(topicsQueryOptions.rootTopic);

    const emptyTopic = { name: "", id: "create", isCategory: false, isRequired: !!required };

    function handleCreate(data: FormType) {
        if (!data.parent) {
            notify.show.error({ message: "Must specify category" });
            return;
        }
        createTopic({
            params: { parentId: data.parent, isCategory: false, isRequired: !!required, name: data.name, description: data.description },
        });
    }

    return (
        <Content style={{ alignItems: "Center", height: "100%" }} paper>
            <Content.Heading backable>
                <Title order={2} fw="normal">
                    Create Topic
                </Title>
                {required && <Badge color="red">Required</Badge>}
            </Content.Heading>
            <TopicForm topic={emptyTopic} parentId={rootTopic.id} onSubmit={handleCreate} />
        </Content>
    );
};

const TopicEditContainer = () => {
    const { topicId } = Route.useParams();
    const {
        data: { topic },
    } = useSuspenseQuery(topicsQueryOptions.topic(topicId));

    const { updateTopicAsync } = useUpdateTopic();
    const { updateTopicParentAsync } = useUpdateTopicParent();

    function handleEdit(data: FormType) {
        const promises = [];
        const parentDiff: string[] = [];
        if (data.name != topic.name || data.description != topic.description) {
            const { name, description } = data;
            promises.push(updateTopicAsync({ topicId: topic.id, params: { name, description } }));
        }
        if (data.parent != topic.parent?.id) {
            if (!data.parent) return;
            parentDiff.push(data.parent);
            promises.push(updateTopicParentAsync({ topicId: topic.id, parentId: data.parent }));
        }
        Promise.all(promises)
            .then(async () =>
                Promise.all([...parentDiff.map((id) => qk.invalidate("topic", "id", id)), qk.invalidate("topic", "id", topicId)]).catch(
                    logger.error,
                ),
            )
            .then(() => notify.show.success({ message: "Updated Topic" }))
            .catch(logger.error);
    }

    useEffect(() => {
        if (!topic.id) debugger;
    }, [topic.id]);

    return (
        <Content style={{ alignItems: "Center", height: "100%" }} paper>
            <Content.Heading backable>
                <Title order={2} fw="normal">
                    Edit Topic
                </Title>
                {topic.isRequired && <Badge color="red">Required</Badge>}
            </Content.Heading>
            <TopicForm topic={topic} onSubmit={handleEdit} parentId={topic.parent!.id} />
        </Content>
    );
};

const TopicContainer = () => {
    const { topicId } = Route.useParams();

    return topicId == "create" ? <TopicCreateContainer /> : <TopicEditContainer />;
};

/**
 * @public
 */
export const Route = createFileRoute("/_auth/organization/topics/$topicId")({
    validateSearch: z.object({ required: z.boolean().optional(), category: z.string().optional() }),
    loader: async ({ params: { topicId } }) => {
        if (topicId != "create") await queryClient.ensureQueryData(topicsQueryOptions.topic(topicId));
    },
    component: TopicContainer,
});
