import { Box, Group, rem, Stack, Title } from "@mantine/core";
import { IconBackhoe } from "@tabler/icons-react";
import { ReactNode } from "react";
import { sidebarLinkData } from "shared/components/global/Sidebar/sidebar.config";
import { SidebarLink, SidebarSubLink } from "shared/components/global/Sidebar/SidebarLink";
import { useUser } from "shared/components/organization/users";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { useIsSupport } from "shared/hooks/useIsSupport";
import { parsePermissions, usePermissions } from "shared/stores/oidc";

export const Sidebar = ({
    navIndex,
    setNavIndex,
    opened,
    toggle,
}: {
    navIndex: number;
    setNavIndex: (idx: number) => unknown;
    opened: boolean;
    toggle: () => void;
}) => {
    const isMobile = useIsMobile();
    const { user } = useUser();
    const { isSupport } = useIsSupport();
    const permissions = usePermissions();

    const subLinks = sidebarLinkData
        .find((link) => link.index == navIndex)
        ?.subLinks?.data.map((link) => {
            if (!!link.permissions && !parsePermissions(permissions, link.permissions)) {
                return;
            }
            if (!!link.hideIf && !isSupport && !!user && !link.hideIf(user)) return;
            return <SidebarSubLink {...link} key={link.label} to={link.to} onClick={isMobile ? toggle : undefined} />;
        });

    return isMobile ? subLinks : <SidebarDesktop navIndex={navIndex} setNavIndex={setNavIndex} opened={opened} subLinks={subLinks} />;
};

const SidebarDesktop = ({
    navIndex,
    setNavIndex,
    opened,
    subLinks,
}: {
    navIndex: number;
    setNavIndex: (idx: number) => unknown;
    opened: boolean;
    subLinks: ReactNode;
}) => {
    const topLinks = sidebarLinkData
        .filter((link) => link.position != "bottom")
        .map((link) => <SidebarLink {...link} key={link.to} onClick={() => setNavIndex(link.index)} />);
    const bottomLinks = sidebarLinkData
        .filter((link) => link.position == "bottom")
        .map((link) => <SidebarLink {...link} key={link.to} onClick={() => setNavIndex(link.index)} />);

    return (
        <Stack component="nav" h="100%" dir="row" style={{ flexWrap: "wrap", gap: 0 }}>
            <Stack justify="space-between" h="100%" w={rem(50)} my={rem(3)}>
                <Stack justify="center" gap={0}>
                    {topLinks}
                </Stack>
                <Stack justify="center" gap={0} mb="xs">
                    {import.meta.env.VITE_ENVIRONMENT !== "prod" && (
                        <SidebarLink to="/devtools" icon={IconBackhoe} label="Dev Sandbox" onClick={() => setNavIndex(-1)} />
                    )}
                    {bottomLinks}
                </Stack>
            </Stack>
            <Stack
                justify="flex-start"
                h="100%"
                w={rem(175)}
                display={opened ? undefined : "none"}
                style={{ borderLeft: "1px solid var(--app-shell-border-color)" }}
            >
                <Box
                    py="xs"
                    h="7dvh"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderBottom: "1px solid var(--app-shell-border-color)",
                    }}
                    fw="bold"
                >
                    <Title order={5}>{sidebarLinkData.find((link) => link.index == navIndex)?.subLinks?.header}</Title>
                </Box>
                <Stack>{subLinks}</Stack>
            </Stack>
        </Stack>
    );
};

export const NavbarFooter = ({ setNavIndex }: { setNavIndex: (idx: number) => unknown }) => {
    const mobileLinks = sidebarLinkData.filter((link) => !link.hideFromMobile);
    return (
        <Group h={rem(50)} justify="space-around">
            {mobileLinks.map((link) => (
                <SidebarLink {...link} key={link.to} onClick={() => setNavIndex(link.index)} />
            ))}
        </Group>
    );
};
