import {
    Accordion,
    AccordionControlProps,
    ActionIcon,
    Anchor,
    Box,
    Button,
    Center,
    Checkbox,
    Combobox,
    Divider,
    Group,
    Input,
    InputLabel,
    Loader,
    Pill,
    PillsInput,
    Stack,
    Switch,
    Text,
    TextInput,
    Title,
    Tooltip,
    useCombobox,
} from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { modals } from "@mantine/modals";
import { IconChevronRight, IconDownload, IconPlus, IconX } from "@tabler/icons-react";
import { queryOptions, useSuspenseInfiniteQuery, useSuspenseQuery } from "@tanstack/react-query";
import { useBlocker, useNavigate } from "@tanstack/react-router";
import { ReactNode, Suspense, useEffect, useState } from "react";
import { httpPostGraphql } from "shared/api/httpClient";
import { LoadMoreTrigger } from "shared/components/global/LoadMoreTrigger";
import { infiniteUserQueryOptions } from "shared/components/organization/users";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { permissionCheck, usePermissions, useUserId } from "shared/stores/oidc";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";
import { Cm, GroupLikeFragment, MemberLikeFragment, Permission, UserQuery } from "src/gql/graphql";

export type StagedMember = {
    firstName?: string;
    lastName?: string;
    id: string;
    euds: {
        type: Cm;
        value: string;
    }[];
};

export const GroupNode = ({
    group,
    checkboxOnClick,
    isDisabledPredicate,
    selectedCheckboxes,
    skipNodePredicate,
    renderLabel,
    shouldRenderCheckbox,
    initialExpand,
}: {
    group: GroupLikeFragment;
    initialExpand?: boolean;
    checkboxOnClick?: (node: GroupLikeFragment) => void;
    selectedCheckboxes?: string[];
    isDisabledPredicate?: (group: GroupLikeFragment) => boolean;
    skipNodePredicate?: (group: GroupLikeFragment) => boolean;
    renderLabel: (group: GroupLikeFragment) => ReactNode;
    shouldRenderCheckbox?: (group: GroupLikeFragment) => boolean;
}) => {
    const [isExpanded, setIsExpanded] = useState(!!initialExpand);

    const { data } = useSuspenseQuery({
        queryKey: qk("group", "id", group.id, "children"),
        queryFn: () => httpPostGraphql(groupsGql.groupChildrenQuery, { id: group.id }),
    });

    const children = data.group.children.filter((c) => !skipNodePredicate?.(c as GroupLikeFragment)) as GroupLikeFragment[];

    const willRenderCheckbox = !!checkboxOnClick && !!selectedCheckboxes && (!!shouldRenderCheckbox ? shouldRenderCheckbox(group) : true);

    const expandButton: ReactNode = (
        <ActionIcon variant="subtle" color="text">
            <IconChevronRight
                size={16}
                opacity={isDisabledPredicate?.(group) ? 0.4 : undefined}
                className={isExpanded ? "expandChevron" : "collapseChevron"}
                onClick={() => setIsExpanded(!isExpanded)}
            />
        </ActionIcon>
    );

    return (
        <Stack gap={0} w="min-content" style={{ display: "inline-flex" }}>
            <Group w="min-content" gap={0} p="sm" wrap="nowrap" style={{ whiteSpace: "nowrap" }}>
                {willRenderCheckbox && (
                    <Checkbox
                        checked={selectedCheckboxes.includes(group.id)}
                        onChange={() => checkboxOnClick!(group)}
                        pr="sm"
                        disabled={isDisabledPredicate?.(group)}
                    />
                )}
                {(children?.length ?? 0) !== 0 && expandButton}
                {renderLabel(group)}
            </Group>
            <Stack gap={0} style={{ display: isExpanded ? undefined : "none" }}>
                <Box
                    w="fit-content"
                    style={{
                        borderLeft: "var(--mantine-spacing-md) solid transparent",
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {children?.map((c) => (
                        <GroupNode
                            key={`${group.id}+${c.id}`}
                            group={c}
                            skipNodePredicate={skipNodePredicate}
                            checkboxOnClick={checkboxOnClick}
                            isDisabledPredicate={isDisabledPredicate}
                            selectedCheckboxes={selectedCheckboxes}
                            renderLabel={renderLabel}
                            shouldRenderCheckbox={shouldRenderCheckbox}
                        />
                    ))}
                </Box>
            </Stack>
        </Stack>
    );
};

export function sharedOrgPageGroupDisabledCheck(
    permissions:
        | { permissions: UserQuery["user"]["clientPermissions"]; isSupport: undefined }
        | { isSupport: true; permissions: undefined },
    group: GroupLikeFragment,
) {
    return !permissionCheck(permissions, Permission.GroupSubgroupManage, group.id);
}

export const SharedOrgPageGroupLabel = (group: GroupLikeFragment) => {
    const nav = useNavigate();
    const permissions = usePermissions();

    return sharedOrgPageGroupDisabledCheck(permissions, group) ? (
        <Text opacity={0.4}>{group.name}</Text>
    ) : (
        <Text onClick={() => void nav({ to: `/organization/groups/$groupId`, params: { groupId: group.id } }).catch(logger.error)}>
            <Anchor c="var(--mantine-color-text)">{group.name}</Anchor>
        </Text>
    );
};

const SectionedAccordionControl = ({ rightSection, ...props }: AccordionControlProps & { rightSection: ReactNode }) => {
    return (
        <Center>
            <Accordion.Control {...props} />
            {rightSection}
        </Center>
    );
};

export const GroupDetailsForm = ({
    form,
    group,
    handleSubmit,
}: {
    form: UseFormReturnType<GroupLikeFragment>;
    group: GroupLikeFragment;
    handleSubmit: (data: GroupLikeFragment) => void;
}) => {
    const { reset } = form;

    useEffect(() => {
        form.setInitialValues({ ...group });
        reset();
    }, [group]);

    useBlocker({
        blockerFn: () => {
            if (window.confirm("This action will lose currently unsaved changes. Continue anyways?")) {
                reset();
                return false;
            }
            return true;
        },
        condition: form.isDirty(),
    });

    return (
        <form
            onSubmit={form.onSubmit((data, e) => {
                e?.preventDefault();
                handleSubmit(data);
            })}
        >
            <Accordion.Item value="group" style={{ borderBottom: "none" }}>
                <SectionedAccordionControl
                    rightSection={
                        <Group wrap="nowrap" pl="md">
                            <Button color="text" type="submit" disabled={!form.isDirty()} leftSection={<IconDownload size={16} />}>
                                Save
                            </Button>

                            <Button
                                variant="default"
                                onClick={(e) => {
                                    e.preventDefault();
                                    reset();
                                }}
                                disabled={!form.isDirty()}
                                type="button"
                            >
                                Reset
                            </Button>
                        </Group>
                    }
                >
                    <Title order={3}>Details</Title>
                </SectionedAccordionControl>
                <Accordion.Panel>
                    <TextInput key={form.key("name")} label="Group Name" placeholder="Enter name" {...form.getInputProps("name")} />
                    <Stack gap={0} pt="sm">
                        <InputLabel>Parent Group(s)</InputLabel>
                        <GroupTreeCombobox
                            initialSelectedGroups={form.getValues().parents ?? []}
                            onChange={(parents) => form.setValues({ parents })}
                            disabled={group.synchronized}
                            currentGroup={group}
                            errorText={(form.errors["parents"] as string) ?? undefined}
                        />
                    </Stack>
                    <Switch
                        key={form.key("internalJoinable")}
                        label="Internally Joinable?"
                        py="sm"
                        size="lg"
                        disabled={group.synchronized}
                        {...form.getInputProps("internalJoinable", { type: "checkbox" })}
                    />
                    <Title order={3}>Sender Profile</Title>
                    <Group p="sm">Placeholder</Group>
                    <Title order={3}>Templates</Title>
                    <Group p="sm">Placeholder</Group>
                </Accordion.Panel>
            </Accordion.Item>
        </form>
    );
};

type GroupNameAndId = { name: string; id: string };

export const GroupTreeCombobox = ({
    currentGroup,
    disabled,
    initialSelectedGroups,
    onChange,
    errorText,
    target,
    shouldRenderCheckbox,
    disabledPredicate,
}: {
    currentGroup?: GroupLikeFragment;
    initialSelectedGroups?: GroupNameAndId[];
    onChange: ((parentIds: GroupNameAndId[]) => void) | ((parentIds: GroupNameAndId[]) => Promise<void>);
    disabled?: boolean;
    errorText?: string;
    target?: (onClick: () => void) => ReactNode;
    shouldRenderCheckbox?: (group: GroupLikeFragment) => boolean;
    disabledPredicate?: (group: GroupLikeFragment) => boolean;
}) => {
    const combobox = useCombobox();
    const permissions = usePermissions();

    const {
        data: { rootGroup },
    } = useSuspenseQuery(groupsQueryOptions.rootGroup);

    function isDisabledPredicate(g: GroupLikeFragment) {
        return !!disabledPredicate
            ? disabledPredicate(g)
            : sharedOrgPageGroupDisabledCheck(permissions, g) || currentGroup?.synchronized || g.synchronized;
    }

    const GroupSelectModal = () => {
        const [selectedCheckboxes, setSelectedCheckboxes] = useState<GroupNameAndId[]>(initialSelectedGroups ?? []);

        return (
            <Box mah="75%">
                <GroupNode
                    group={rootGroup}
                    initialExpand={true}
                    checkboxOnClick={(node: GroupLikeFragment) => {
                        setSelectedCheckboxes(
                            selectedCheckboxes.some((g) => g.id == node.id)
                                ? selectedCheckboxes.filter((g) => g.id != node.id)
                                : selectedCheckboxes.concat(node),
                        );
                    }}
                    selectedCheckboxes={selectedCheckboxes.map((g) => g.id)}
                    isDisabledPredicate={isDisabledPredicate}
                    skipNodePredicate={(g) => g.id == currentGroup?.id}
                    renderLabel={(g) => <Text opacity={isDisabledPredicate(g) ? 0.4 : undefined}>{g.name}</Text>}
                    shouldRenderCheckbox={shouldRenderCheckbox}
                />
                <Divider />
                <Group pt="sm">
                    <Button variant="default" onClick={modals.closeAll} style={{ flexGrow: 1 }}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            onChange(selectedCheckboxes)?.catch(logger.error);
                            modals.closeAll();
                        }}
                        style={{ flexGrow: 1 }}
                    >
                        Continue
                    </Button>
                </Group>
            </Box>
        );
    };

    function openGroupSelectModal() {
        modals.open({
            title: "Select Groups",
            children: (
                <Suspense fallback={<Loader />}>
                    <GroupSelectModal />
                </Suspense>
            ),
            centered: true,
        });
    }

    return (
        <>
            <Combobox store={combobox}>
                <Combobox.DropdownTarget>
                    {!!target ? (
                        target(openGroupSelectModal)
                    ) : (
                        <PillsInput
                            pointer
                            onClick={() => {
                                if (!disabled && currentGroup?.id !== rootGroup.id) openGroupSelectModal();
                            }}
                            disabled={disabled || currentGroup?.id == rootGroup.id}
                            error={errorText}
                        >
                            <Pill.Group>
                                {!!initialSelectedGroups && initialSelectedGroups.length > 0 ? (
                                    initialSelectedGroups.map((g) => (
                                        <Pill
                                            key={g.id}
                                            withRemoveButton
                                            disabled={disabled}
                                            onRemove={() => {
                                                onChange(initialSelectedGroups.filter((x) => x.id != g.id))?.catch(logger.error);
                                            }}
                                        >
                                            {g.name}
                                        </Pill>
                                    ))
                                ) : currentGroup?.id == rootGroup.id ? (
                                    <Text fs="italic">This group cannot have any parents</Text>
                                ) : (
                                    <Input.Placeholder>Pick one or more values</Input.Placeholder>
                                )}
                                <Combobox.EventsTarget>
                                    <PillsInput.Field
                                        type="hidden"
                                        onBlur={() => combobox.closeDropdown()}
                                        onKeyDown={(event) => {
                                            if (event.key === "Backspace") {
                                                event.preventDefault();
                                                onChange(initialSelectedGroups?.slice(0, -1) ?? [])?.catch(logger.error);
                                            }
                                        }}
                                    />
                                </Combobox.EventsTarget>
                            </Pill.Group>
                        </PillsInput>
                    )}
                </Combobox.DropdownTarget>
            </Combobox>
        </>
    );
};

const AddMembersModal = ({
    groupId,
    initialMembers,
    isCreateForm,
    setMembersOverride,
}: {
    groupId: string;
    initialMembers: string[];
    isCreateForm: boolean;
    setMembersOverride?: (members: StagedMember[]) => void;
}) => {
    const [newMembers, setNewMembers] = useState<string[]>([]);
    const {
        data: { pages },
    } = useSuspenseInfiniteQuery(infiniteUserQueryOptions);
    const members = pages.flat();

    const { mutate: addMembersMutation } = useGraphqlMutation({
        document: mutations.addMembersToGroup,
        onSuccess: async () => {
            await Promise.all([
                ...newMembers.map((id) => qk.invalidate("user", "id", id)),
                qk.invalidate("group", "id", groupId, "members"),
            ]);
            notify.show.success({ message: "Added users to group" });
        },
        onError: () => notify.show.error({ message: "Error adding users to group" }),
    });

    // TODO: Search
    return (
        <Stack>
            <Stack mah="50dvh" style={{ overflowY: "auto" }}>
                {members.map((m) => {
                    const maybeEud = m.contactInfo.euds.find((eud) => eud.type == Cm.Email);
                    const checked = newMembers.includes(m.userId) || initialMembers.includes(m.userId);
                    return (
                        <Checkbox
                            key={m.userId}
                            label={m.contactInfo.name ?? "Unknown user"}
                            description={!!maybeEud ? maybeEud.value : ""}
                            size="md"
                            checked={checked}
                            disabled={initialMembers.includes(m.userId)}
                            onChange={() =>
                                setNewMembers(checked ? newMembers.filter((id) => id != m.userId) : newMembers.concat(m.userId))
                            }
                        />
                    );
                })}
                <LoadMoreTrigger query={infiniteUserQueryOptions} />
            </Stack>
            <Divider />
            <Button
                w="100%"
                onClick={() => {
                    if (isCreateForm) {
                        setMembersOverride?.(
                            members
                                .filter((m) => newMembers.includes(m.userId))
                                .map((m) => {
                                    const name = m.contactInfo.name.split(" ");
                                    return {
                                        firstName: name[0] ?? undefined,
                                        lastName: name[1] ?? undefined,
                                        id: m.userId,
                                        euds: m.contactInfo.euds,
                                    };
                                }),
                        );
                    } else addMembersMutation({ groupId, userIds: newMembers });
                    modals.closeAll();
                }}
            >
                Add
            </Button>
        </Stack>
    );
};

const Member = ({
    member,
    canChangeMembership,
    onRemove,
}: {
    member: StagedMember;
    canChangeMembership: boolean;
    onRemove: () => void;
}) => {
    const maybeEud = member.euds.find((eud) => eud.type == Cm.Email);

    return (
        <Group key={member.id} justify="space-between">
            <Stack gap={0}>
                <Text>{member.firstName || member.lastName ? `${member.firstName ?? ""} ${member.lastName ?? ""}` : "Unknown user"}</Text>
                <Text fz="sm">{!!maybeEud ? maybeEud.value : ""}</Text>
            </Stack>
            <ActionIcon variant="transparent" color="text" size="sm" disabled={!canChangeMembership} onClick={onRemove}>
                <IconX size={16} />
            </ActionIcon>
        </Group>
    );
};

export const MemberDetails = ({
    members,
    groupId,
    membersOverride,
    setMembersOverride,
    canViewMembership,
    canChangeMembership,
    isCreateForm,
}: {
    members: MemberLikeFragment[];
    groupId: string;
    membersOverride?: StagedMember[];
    setMembersOverride?: (members: StagedMember[]) => void;
    canViewMembership: boolean;
    canChangeMembership: boolean;
    isCreateForm: boolean;
}) => {
    const { mutate: removeMemberMutation } = useGraphqlMutation({
        document: mutations.removeMembersFromGroup,
        onSuccess: () => {
            notify.show.success({ message: "Removed user from group" });
        },
        onError: () => notify.show.error({ message: "Failed to removed user from group" }),
    });

    return (
        <Accordion.Item value="members" style={{ borderBottom: "none" }}>
            <SectionedAccordionControl
                rightSection={
                    <Tooltip label="You do not have permission to add members to this group" disabled={canChangeMembership}>
                        <Button
                            variant="default"
                            leftSection={<IconPlus size={16} />}
                            flex="1 0 auto"
                            onClick={() =>
                                modals.open({
                                    title: "Add members",
                                    centered: true,
                                    children: (
                                        <AddMembersModal
                                            groupId={groupId}
                                            initialMembers={members.map((m) => m.id)}
                                            isCreateForm={isCreateForm}
                                            setMembersOverride={setMembersOverride}
                                        />
                                    ),
                                })
                            }
                            disabled={!canChangeMembership}
                        >
                            Add
                        </Button>
                    </Tooltip>
                }
            >
                <Title order={3}>Members</Title>
            </SectionedAccordionControl>
            <Accordion.Panel>
                <Box style={{ flexGrow: 1 }}>
                    <Stack style={{ overflowY: "auto" }}>
                        {!!membersOverride && membersOverride.length > 0 ? (
                            membersOverride.map((member) => (
                                <Member
                                    key={member.id}
                                    member={member}
                                    canChangeMembership
                                    onRemove={() => {
                                        setMembersOverride?.(membersOverride.filter((m) => m.id != member.id));
                                    }}
                                />
                            ))
                        ) : canViewMembership && members.length > 0 ? (
                            members.map((member) => (
                                <Member
                                    key={member.id}
                                    member={{
                                        firstName: member.firstName ?? undefined,
                                        lastName: member.lastName ?? undefined,
                                        id: member.id,
                                        euds: member.euds,
                                    }}
                                    canChangeMembership={canChangeMembership}
                                    onRemove={() => {
                                        removeMemberMutation({ userId: member.id, groupId: groupId });
                                        Promise.all([qk.invalidate("group"), qk.invalidate("user", "id", member.id)]).catch(logger.error);
                                    }}
                                />
                            ))
                        ) : (
                            <Stack align="center">
                                <Text c="dimmed">No members found</Text>
                            </Stack>
                        )}
                    </Stack>
                </Box>
            </Accordion.Panel>
        </Accordion.Item>
    );
};

export function useCreateGroup() {
    const { mutate: createGroup } = useGraphqlMutation({
        document: mutations.createGroup,
        onSuccess: async () => {
            await Promise.all([qk.invalidate("user"), qk.invalidate("group")]);
            notify.show.success({ message: "Created new group" });
        },
    });
    return { createGroup };
}

// Query key invalidation occurs in src/routes/_auth/organization/groups/$groupId.tsx
// for the following three mutations
export function useUpdateGroup() {
    const { mutateAsync: updateGroupAsync } = useGraphqlMutation(mutations.updateGroup);
    return { updateGroupAsync };
}

export function useAddParentToGroup() {
    const { mutateAsync: addParentAsync } = useGraphqlMutation(mutations.addParent);
    return { addParentAsync };
}

export function useRemoveParentFromGroup() {
    const { mutateAsync: removeParentAsync } = useGraphqlMutation(mutations.removeParent);
    return { removeParentAsync };
}

export function useAddUserToGroup() {
    const { userId } = useUserId();
    const { mutateAsync: addUserAsync } = useGraphqlMutation({
        document: mutations.addUserToGroup,
        onSuccess: async (data) => {
            if (!!data?.addUserToGroup) {
                const group = data.addUserToGroup as GroupLikeFragment;
                await Promise.all([qk.invalidate("user", "id", userId), qk.invalidate("group", "id", group.id, "members")]);
            }
        },
    });
    return { addUserAsync };
}

export function useRemoveUserFromGroup() {
    const { userId } = useUserId();
    const { mutate: removeUser } = useGraphqlMutation({
        document: mutations.removeUserFromGroup,
        onSuccess: async (data) => {
            const groupId = data?.removeUserFromGroup.id;
            await Promise.all([qk.invalidate("user", "id", userId), !!groupId && qk.invalidate("group", "id", groupId, "members")]);
            notify.show.success({ message: "Successfully left group" });
        },
    });
    return { removeUser };
}

const mutations = {
    createGroup: graphql(`
        mutation CreateGroup($internalJoinable: Boolean!, $name: String!, $parentGroupIds: [UUID!]!, $members: [UUID!]!) {
            createGroup(params: { internalJoinable: $internalJoinable, name: $name, parentGroupIds: $parentGroupIds, members: $members }) {
                parents {
                    id
                }
            }
        }
    `),
    addMembersToGroup: graphql(`
        mutation AddUsersToGroup($groupId: UUID!, $userIds: [UUID!]!) {
            addUsersToGroup(groupId: $groupId, userIds: $userIds) {
                id
            }
        }
    `),
    removeMembersFromGroup: graphql(`
        mutation RemoveGroupMember($userId: UUID!, $groupId: UUID!) {
            removeUserFromGroup(userId: $userId, groupId: $groupId) {
                id
            }
        }
    `),
    updateGroup: graphql(`
        mutation UpdateGroup($groupId: UUID!, $internalJoinable: Boolean!, $name: String!) {
            updateGroup(id: $groupId, params: { internalJoinable: $internalJoinable, name: $name }) {
                id
            }
        }
    `),
    addParent: graphql(`
        mutation AddParentGrouop($groupId: UUID!, $parentId: UUID!) {
            addParentToGroup(groupId: $groupId, parentId: $parentId) {
                id
            }
        }
    `),
    removeParent: graphql(`
        mutation RemoveParentGroup($groupId: UUID!, $parentId: UUID!) {
            removeParentFromGroup(groupId: $groupId, parentId: $parentId) {
                id
            }
        }
    `),
    addUserToGroup: graphql(`
        mutation AddUserToGroup($groupId: UUID!, $userId: UUID!) {
            addUserToGroup(groupId: $groupId, userId: $userId) {
                ...GroupLike
            }
        }
    `),
    removeUserFromGroup: graphql(`
        mutation RemoveUserFromGroup($groupId: UUID!, $userId: UUID!) {
            removeUserFromGroup(groupId: $groupId, userId: $userId) {
                id
            }
        }
    `),
};

export const groupsQueryOptions = {
    rootGroup: queryOptions({
        queryKey: qk("group", "rootGroup"),
        queryFn: () => httpPostGraphql(groupsGql.rootGroupQuery, {}),
        select: ({ rootGroup }) => ({ rootGroup: rootGroup as GroupLikeFragment }),
    }),
    groupDetails: (id: string) =>
        queryOptions({
            queryKey: qk("group", "id", id, "details"),
            queryFn: () => httpPostGraphql(groupsGql.groupQuery, { id }),
            select: ({ group }) => ({ group: group as GroupLikeFragment }),
        }),
    members: (id: string) =>
        queryOptions({
            queryKey: qk("group", "id", id, "members"),
            queryFn: () => httpPostGraphql(groupsGql.groupMembersQuery, { id }),
        }),
};

const groupsGql = {
    groupFragment: graphql(`
        fragment GroupLike on GroupNode {
            id
            name
            internalJoinable
            synchronized
            parents {
                id
                name
            }
        }
    `),
    membersFragment: graphql(`
        fragment MemberLike on UserNode {
            id
            firstName
            lastName
            euds {
                type
                value
            }
        }
    `),
    rootGroupQuery: graphql(`
        query RootGroup {
            rootGroup {
                ...GroupLike
            }
        }
    `),
    groupQuery: graphql(`
        query Group($id: UUID!) {
            group(id: $id) {
                ...GroupLike
            }
        }
    `),
    groupChildrenQuery: graphql(`
        query GroupChildren($id: UUID!) {
            group(id: $id) {
                children {
                    ...GroupLike
                }
            }
        }
    `),
    groupMembersQuery: graphql(`
        query GroupMembers($id: UUID!) {
            group(id: $id) {
                members {
                    ...MemberLike
                }
            }
        }
    `),
};
