import {
    Badge,
    Box,
    Button,
    Center,
    Combobox,
    Group,
    Input,
    InputBase,
    Loader,
    rem,
    ScrollArea,
    Select,
    Stack,
    Text,
    TextInput,
    Title,
    useCombobox,
    useMantineTheme,
} from "@mantine/core";
import { useMap } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconEdit, IconPlus, IconSearch, IconTrash, IconX } from "@tabler/icons-react";
import { useSuspenseInfiniteQuery, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { MantineReactTable, type MRT_ColumnDef, MRT_RowSelectionState, useMantineReactTable } from "mantine-react-table";
import { Suspense, type UIEvent, useCallback, useMemo, useRef, useState } from "react";
import { Content } from "shared/components/global/Content";
import { LoadMoreTrigger } from "shared/components/global/LoadMoreTrigger";
import { GroupNode, groupsQueryOptions } from "shared/components/organization/groups";
import { TopicNode, topicsQueryOptions } from "shared/components/organization/topics";
import {
    infiniteUserQueryOptions,
    useAssignCustomerRole,
    useAssignGroupRole,
    useAssignTopicRole,
    useDeleteUser,
    useUnassignRole,
} from "shared/components/organization/users";
import { RelativeTime } from "shared/components/RelativeTime";
import { USERS_TABLE_ROW_HEIGHT_PX } from "shared/const";
import { useColorMode } from "shared/hooks/useColorMode";
import { permissionCheck, usePermissions } from "shared/stores/oidc";
import { settlePromises, uniqueBy } from "shared/utils/fns";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { createOffsetBasedGqlQueryOptions } from "shared/utils/query";
import { graphql } from "src/gql";
import { Cm, GroupLikeFragment, Permission, PermissionType, TopicLikeFragment } from "src/gql/graphql";
import { queryClient } from "src/queryClient";
import { match } from "ts-pattern";
import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const userSchema = z.object({
    userId: z.string(),
    contactInfo: z.object({
        name: z.string(),
        euds: z
            .object({
                type: z.nativeEnum(Cm),
                value: z.string(),
            })
            .array(),
    }),
    roles: z
        .object({
            id: z.string(),
            role: z.object({
                id: z.string(),
                name: z.string(),
                type: z.nativeEnum(PermissionType),
            }),
            group: z.object({ id: z.string() }).nullish(),
            topic: z.object({ id: z.string() }).nullish(),
        })
        .array(),
    groups: z
        .object({
            name: z.string(),
        })
        .array(),
    lastActive: z.string().datetime().nullish(),
});

type User = z.infer<typeof userSchema>;

const columns: MRT_ColumnDef<User>[] = [
    {
        accessorKey: "contactInfo",
        header: "Name",
        Cell: ({ cell }) => {
            const { name, euds } = cell.getValue<User["contactInfo"]>();
            const maybeEud = euds.find((eud) => eud.type == Cm.Email);
            return (
                <Stack
                    gap={0}
                    h={USERS_TABLE_ROW_HEIGHT_PX}
                    mah={USERS_TABLE_ROW_HEIGHT_PX}
                    style={{ overflow: "hidden" }}
                    justify="center"
                >
                    <Text fz="lg">{name == " " ? "No name" : name}</Text>
                    {!!maybeEud && <Text fz="sm">{maybeEud.value}</Text>}
                </Stack>
            );
        },
    },
    {
        accessorKey: "roles",
        header: "Roles",
        Cell: ({ cell, row }) => {
            const roles = cell.getValue<User["roles"]>();

            return (
                <Group h={USERS_TABLE_ROW_HEIGHT_PX} mah={USERS_TABLE_ROW_HEIGHT_PX} style={{ overflow: "hidden" }}>
                    {roles.length == 0 ? (
                        <Badge
                            color="gray"
                            variant="outline"
                            component="button"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                                modals.open({ title: "Assign Role", children: <RoleAssignModal userIds={[row.original.userId]} /> })
                            }
                        >
                            None
                        </Badge>
                    ) : (
                        roles.filter(uniqueBy(({ role: { id } }) => id)).map((role) => (
                            <Badge
                                key={role.role.id + (role.group?.id ?? "") + (role.topic?.id ?? "")}
                                color={!!role.group ? "blue" : !!role.topic ? "green" : "yellow"}
                                variant="outline"
                                component="button"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    modals.open({
                                        title: "Edit Role",
                                        children: <RoleEditModal user={row.original} role={role.role} />,
                                    })
                                }
                            >
                                {role.role.name}
                            </Badge>
                        ))
                    )}
                </Group>
            );
        },
    },
    {
        accessorKey: "groups",
        header: "Groups",
        Cell: ({ cell }) => {
            const groups = cell
                .getValue<User["groups"]>()
                .map((g) => g.name)
                .join(", ");
            return (
                <Center h={USERS_TABLE_ROW_HEIGHT_PX} mah={USERS_TABLE_ROW_HEIGHT_PX} style={{ display: "flex", alignItems: "center" }}>
                    <Text lineClamp={2}>{groups}</Text>
                </Center>
            );
        },
    },
    {
        accessorKey: "lastActive",
        header: "Last Active",
        size: 100,
        Cell: ({ cell }) => {
            const lastActive = cell.getValue<User["lastActive"]>();
            return !!lastActive ? <RelativeTime date={parseInt(lastActive)} /> : <Text>Never</Text>;
        },
    },
];

function roleMutationNotifyOpts() {
    async function invalidateAndCloseModal() {
        await qk.invalidate("user");
        modals.closeAll();
    }
    return {
        onSuccess: async () => {
            await invalidateAndCloseModal();
            notify.show.success({ message: "Roles updated" });
        },
        onSomeFailed: async (numFailed: number) => {
            await invalidateAndCloseModal();
            notify.show.warn({ message: `${numFailed} user(s) could not be updated` });
        },
        onAllFailed: async () => {
            await invalidateAndCloseModal();
            notify.show.error({ message: "Roles could not be updated" });
        },
    };
}

const FAB = ({ onClear, onAssign, onRemove }: { onClear: () => void; onAssign: () => void; onRemove: () => void }) => {
    const theme = useMantineTheme();
    const { isDark } = useColorMode();

    return (
        <Group
            justify="center"
            p="xs"
            gap={0}
            style={{
                position: "absolute",
                bottom: rem(20),
                left: 0,
                right: 0,
                marginInline: "auto",
                backgroundColor: isDark ? theme.colors.dark[4] : theme.colors.gray[2],
                borderRadius: "0.5rem",
                width: "fit-content",
            }}
        >
            <Button leftSection={<IconTrash size={16} />} variant="subtle" onClick={onRemove}>
                Remove User
            </Button>
            <Button leftSection={<IconEdit size={16} />} variant="subtle" onClick={onAssign}>
                Assign Roles
            </Button>
            <Button leftSection={<IconX size={16} />} onClick={onClear} variant="subtle">
                Clear
            </Button>
        </Group>
    );
};

const GroupAssignComponent = ({ userIds, selectedRoleId }: { userIds: string[]; selectedRoleId: string }) => {
    const permissions = usePermissions();
    const {
        data: { rootGroup },
    } = useSuspenseQuery(groupsQueryOptions.rootGroup);
    const { assignGroupRoleAsync } = useAssignGroupRole();

    const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);

    function isDisabledPredicate(g: GroupLikeFragment) {
        return !permissionCheck(permissions, Permission.GroupRolesManage, g.id);
    }

    return (
        <Stack gap="xs">
            <Title order={4}>Select Group</Title>
            <Text>Select at least one group for this role.</Text>
            <Box mah="400" style={{ overflow: "auto" }}>
                <GroupNode
                    group={rootGroup}
                    initialExpand={true}
                    isDisabledPredicate={isDisabledPredicate}
                    renderLabel={(group) => <Text opacity={isDisabledPredicate(group) ? 0.4 : undefined}>{group.name}</Text>}
                    checkboxOnClick={(node) =>
                        setSelectedCheckboxes(
                            selectedCheckboxes.some((n) => n == node.id)
                                ? selectedCheckboxes.filter((n) => n != node.id)
                                : selectedCheckboxes.concat(node.id),
                        )
                    }
                    selectedCheckboxes={selectedCheckboxes}
                    shouldRenderCheckbox={(g) => !isDisabledPredicate(g)}
                />
            </Box>
            <Button
                w="100%"
                disabled={selectedCheckboxes.length == 0}
                onClick={() => {
                    const promises = userIds
                        .map((id) =>
                            selectedCheckboxes.map((groupId) => assignGroupRoleAsync({ userId: id, roleId: selectedRoleId, groupId })),
                        )
                        .flat();
                    settlePromises({
                        promises,
                        ...roleMutationNotifyOpts(),
                    }).catch(logger.error);
                }}
            >
                Save
            </Button>
        </Stack>
    );
};

const GroupEditComponent = ({
    user,
    selectedRoleId,
    initialGroupIds,
}: {
    user: User;
    selectedRoleId: string;
    initialGroupIds: string[];
}) => {
    const permissions = usePermissions();
    const {
        data: { rootGroup },
    } = useSuspenseQuery(groupsQueryOptions.rootGroup);
    const { assignGroupRoleAsync } = useAssignGroupRole();
    const { unassignRoleAsync } = useUnassignRole();

    const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>(initialGroupIds);
    const groupDiff = useMap<string, "add" | "remove">();

    function isDisabledPredicate(g: GroupLikeFragment) {
        return !permissionCheck(permissions, Permission.GroupRolesManage, g.id);
    }

    return (
        <Stack gap="xs">
            <Title order={4}>Select Group</Title>
            <Text>Select at least one group for this role.</Text>
            <Box mah="400" style={{ overflow: "auto" }}>
                <GroupNode
                    group={rootGroup}
                    initialExpand={true}
                    isDisabledPredicate={isDisabledPredicate}
                    renderLabel={(group) => <Text opacity={isDisabledPredicate(group) ? 0.4 : undefined}>{group.name}</Text>}
                    checkboxOnClick={(node) => {
                        if (selectedCheckboxes.some((n) => n == node.id)) {
                            setSelectedCheckboxes(selectedCheckboxes.filter((n) => n != node.id));
                            if (initialGroupIds.includes(node.id)) groupDiff.set(node.id, "remove");
                            else if (groupDiff.has(node.id)) groupDiff.delete(node.id);
                        } else {
                            setSelectedCheckboxes(selectedCheckboxes.concat(node.id));
                            if (!initialGroupIds.includes(node.id)) groupDiff.set(node.id, "add");
                            else if (groupDiff.has(node.id)) groupDiff.delete(node.id);
                        }
                    }}
                    selectedCheckboxes={selectedCheckboxes}
                    shouldRenderCheckbox={(g) => !isDisabledPredicate(g)}
                />
            </Box>
            <Button
                w="100%"
                disabled={groupDiff.size == 0}
                onClick={() => {
                    const promises = Array.from(groupDiff).map(([id, op]) => {
                        if (op == "add") return assignGroupRoleAsync({ userId: user.userId, roleId: selectedRoleId, groupId: id });
                        else {
                            const userRoleId = user.roles.find((r) => r.role.id == selectedRoleId && r.group?.id == id)?.id;
                            if (!userRoleId)
                                return Promise.reject(
                                    `userRoleId not found on user ${user.userId} for role ${selectedRoleId} for group ${id}`,
                                );
                            else return unassignRoleAsync({ userId: user.userId, userRoleId: userRoleId! });
                        }
                    });

                    settlePromises({
                        promises,
                        ...roleMutationNotifyOpts(),
                    }).catch(logger.error);
                }}
            >
                Save
            </Button>
        </Stack>
    );
};

const TopicAssignComponent = ({ userIds, selectedRoleId }: { userIds: string[]; selectedRoleId: string }) => {
    const {
        data: { rootTopic },
    } = useSuspenseQuery(topicsQueryOptions.rootTopic);
    const { assignTopicRoleAsync } = useAssignTopicRole();

    const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);

    return (
        <Stack gap="xs">
            <Title order={4}>Select Topic</Title>
            <Text>Select at least one topic for this role.</Text>
            <Stack gap={0} mah={rem(400)} style={{ overflow: "auto" }}>
                {rootTopic.children.map((c) => (
                    <TopicNode
                        initialCheckedTopicIds={[]}
                        initialExpandedNodes={rootTopic.children
                            .filter((c: TopicLikeFragment) => c.isCategory)
                            .map((c: TopicLikeFragment) => c.id)}
                        key={c.id}
                        topic={c}
                        shouldRenderCheckbox={() => true}
                        checkboxOnChange={(id, checked) => {
                            if (checked) setSelectedCheckboxes(selectedCheckboxes.concat(id));
                            else setSelectedCheckboxes(selectedCheckboxes.filter((t) => t != id));
                        }}
                    />
                ))}
            </Stack>
            <Button
                w="100%"
                disabled={selectedCheckboxes.length == 0}
                onClick={() => {
                    const promises = userIds
                        .map((id) =>
                            selectedCheckboxes.map((topicId) => assignTopicRoleAsync({ userId: id, roleId: selectedRoleId, topicId })),
                        )
                        .flat();
                    settlePromises({
                        promises,
                        ...roleMutationNotifyOpts(),
                    }).catch(logger.error);
                }}
            >
                Save
            </Button>
        </Stack>
    );
};

const TopicEditComponent = ({
    user,
    selectedRoleId,
    initialTopicIds,
}: {
    user: User;
    selectedRoleId: string;
    initialTopicIds: string[];
}) => {
    const {
        data: { rootTopic },
    } = useSuspenseQuery(topicsQueryOptions.rootTopic);
    const { assignTopicRoleAsync } = useAssignTopicRole();
    const { unassignRoleAsync } = useUnassignRole();

    const topicDiff = useMap<string, "add" | "remove">();

    return (
        <Stack gap="xs">
            <Title order={4}>Select Topic</Title>
            <Text>Select at least one topic for this role.</Text>
            <Stack gap={0} mah="400" style={{ overflow: "auto" }}>
                {rootTopic.children.map((c) => (
                    <TopicNode
                        initialExpandedNodes={rootTopic.children.filter((c) => c.isCategory).map((c) => c.id)}
                        key={c.id}
                        topic={c}
                        shouldRenderCheckbox={() => true}
                        checkboxOnChange={(id, checked) => {
                            if (checked) {
                                if (!initialTopicIds.includes(id)) topicDiff.set(id, "add");
                                else if (topicDiff.has(id)) topicDiff.delete(id);
                            } else {
                                if (initialTopicIds.includes(id)) topicDiff.set(id, "remove");
                                else if (topicDiff.has(id)) topicDiff.delete(id);
                            }
                        }}
                        initialCheckedTopicIds={initialTopicIds}
                    />
                ))}
            </Stack>
            <Button
                w="100%"
                disabled={topicDiff.size == 0}
                onClick={() => {
                    const promises = Array.from(topicDiff).map(([id, op]) => {
                        if (op == "add") return assignTopicRoleAsync({ userId: user.userId, roleId: selectedRoleId, topicId: id });
                        else {
                            const userRoleId = user.roles.find((r) => r.role.id == selectedRoleId && r.topic?.id == id)?.id;
                            if (!userRoleId)
                                return Promise.reject(
                                    `userRoleId not found on user ${user.userId} for role ${selectedRoleId} for topic ${id}`,
                                );
                            else return unassignRoleAsync({ userId: user.userId, userRoleId: userRoleId! });
                        }
                    });

                    settlePromises({
                        promises,
                        ...roleMutationNotifyOpts(),
                    }).catch(logger.error);
                }}
            >
                Save
            </Button>
        </Stack>
    );
};

const RoleAssignModal = ({ userIds }: { userIds: string[] }) => {
    const permissions = usePermissions();
    const {
        data: { pages },
    } = useSuspenseInfiniteQuery(rolesQuery);
    const unfilteredRoles = pages.flat();

    const { assignCustomerRoleAsync } = useAssignCustomerRole();

    const roles = useMemo(() => {
        const canModifyTopicRoles = permissionCheck(permissions, Permission.TopicRolesManage);
        const canModifyGroupRoles = permissionCheck(permissions, Permission.GroupRolesManage);
        const canModifyCustomerRoles = permissionCheck(permissions, Permission.CustomerRolesManage);
        return unfilteredRoles.filter((role) =>
            match(role.type)
                .with(PermissionType.Customer, () => canModifyCustomerRoles)
                .with(PermissionType.Group, () => canModifyGroupRoles)
                .with(PermissionType.Topic, () => canModifyTopicRoles)
                .exhaustive(),
        );
    }, [unfilteredRoles, permissions]);

    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    });
    const [selectedRole, setSelectedRole] = useState<User["roles"][0]["role"]>();

    const options = roles.map((role) => (
        <Combobox.Option value={role.id} key={role.id}>
            {role.name}
        </Combobox.Option>
    ));

    return (
        <Stack pos="relative">
            <Combobox
                onOptionSubmit={(val) => {
                    combobox.closeDropdown();
                    setSelectedRole(roles.find((r) => r.id == val));
                }}
                store={combobox}
            >
                <Combobox.Target>
                    <InputBase
                        component="button"
                        type="button"
                        label="Role"
                        pointer
                        withAsterisk
                        rightSection={<Combobox.Chevron />}
                        rightSectionPointerEvents="none"
                        onClick={() => combobox.toggleDropdown()}
                        onBlur={() => combobox.closeDropdown()}
                    >
                        {!!selectedRole ? selectedRole.name : <Input.Placeholder>Pick role</Input.Placeholder>}
                    </InputBase>
                </Combobox.Target>
                <Combobox.Dropdown>
                    <Combobox.Options>
                        <ScrollArea.Autosize type="scroll" mah={200}>
                            {options.length === 0 ? <Combobox.Empty>No available roles</Combobox.Empty> : options}
                            <LoadMoreTrigger query={rolesQuery} />
                        </ScrollArea.Autosize>
                    </Combobox.Options>
                </Combobox.Dropdown>
            </Combobox>
            {!!selectedRole &&
                match(selectedRole.type)
                    .with(PermissionType.Customer, () => (
                        <Button
                            w="100%"
                            onClick={() => {
                                const promises = userIds.map((id) => assignCustomerRoleAsync({ userId: id, roleId: selectedRole.id }));
                                settlePromises({
                                    promises,
                                    ...roleMutationNotifyOpts(),
                                }).catch(logger.error);
                            }}
                        >
                            Save
                        </Button>
                    ))
                    .with(PermissionType.Group, () => (
                        <Suspense fallback={<Loader />}>
                            <GroupAssignComponent userIds={userIds} selectedRoleId={selectedRole.id} />
                        </Suspense>
                    ))
                    .with(PermissionType.Topic, () => (
                        <Suspense fallback={<Loader />}>
                            <TopicAssignComponent userIds={userIds} selectedRoleId={selectedRole.id} />
                        </Suspense>
                    ))
                    .exhaustive()}
        </Stack>
    );
};

const RoleEditModal = ({ user, role }: { user: User; role: User["roles"][0]["role"] }) => {
    const { unassignRoleAsync } = useUnassignRole();

    const initialList = match(role.type)
        .with(PermissionType.Customer, () => undefined)
        .with(PermissionType.Group, () => user.roles.filter((r) => r.role.id == role.id).map((r) => r.group!.id))
        .with(PermissionType.Topic, () => user.roles.filter((r) => r.role.id == role.id).map((r) => r.topic!.id))
        .exhaustive();

    return (
        <Stack>
            <Select label="Role" withAsterisk defaultValue={role.name} data={[role.name]} disabled />

            {match(role.type)
                .with(PermissionType.Customer, () => (
                    <Button
                        w="100%"
                        leftSection={<IconTrash size={16} />}
                        onClick={(e) => {
                            e.preventDefault();
                            unassignRoleAsync({ userId: user.userId, userRoleId: role.id }).catch(logger.error);
                        }}
                    >
                        Remove Role Assignment
                    </Button>
                ))
                .with(PermissionType.Group, () => (
                    <Suspense fallback={<Loader />}>
                        <GroupEditComponent user={user} selectedRoleId={role.id} initialGroupIds={initialList!} />
                    </Suspense>
                ))
                .with(PermissionType.Topic, () => (
                    <Suspense fallback={<Loader />}>
                        <TopicEditComponent user={user} selectedRoleId={role.id} initialTopicIds={initialList!} />
                    </Suspense>
                ))
                .exhaustive()}
        </Stack>
    );
};

const UsersDataTable = () => {
    const tableContainerRef = useRef<HTMLDivElement>(null);
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const selectedUsers = Object.keys(rowSelection);

    const {
        data: { pages },
        isError,
        hasNextPage,
        fetchNextPage,
        isFetching,
    } = useSuspenseInfiniteQuery(infiniteUserQueryOptions);
    const users = pages.flat();

    const { deleteUserAsync } = useDeleteUser();

    function openConfirmDeleteModal({ numUsers, onConfirm }: { numUsers: number; onConfirm: () => void }) {
        modals.openConfirmModal({
            title: `Delete ${numUsers} User(s)?`,
            centered: true,
            children: <Text>Are you sure you want to permanently delete the selected user(s)? This action cannot be undone.</Text>,
            labels: { confirm: `Delete ${numUsers} user(s)`, cancel: "Cancel" },
            onConfirm,
        });
    }

    function openRoleAssignModal() {
        modals.open({
            title: "Assign Role",
            centered: true,
            children: <RoleAssignModal userIds={selectedUsers} />,
        });
    }

    const table = useMantineReactTable({
        columns,
        data: users,
        enableColumnActions: false,
        enableBottomToolbar: false,
        enablePagination: false,
        enableRowSelection: true,
        enableSelectAll: false,
        enableRowVirtualization: true,
        enableSorting: false,
        enableTopToolbar: false,
        getRowId: (row) => row.userId,
        mantineTableContainerProps: {
            ref: tableContainerRef,
            style: { maxHeight: "70vh", flexGrow: 1 },
            onScroll: (event: UIEvent<HTMLDivElement>) => fetchMore(event.target as HTMLDivElement),
        },
        state: {
            rowSelection,
            showAlertBanner: isError,
        },
        rowVirtualizerOptions: {
            overscan: 5,
        },
        onRowSelectionChange: setRowSelection,
    });

    const fetchMore = useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
                if (scrollHeight - scrollTop - clientHeight < 200 && !isFetching && hasNextPage) {
                    fetchNextPage().catch(logger.error);
                }
            }
        },
        [fetchNextPage, isFetching, hasNextPage],
    );

    return (
        <Stack pos="relative">
            <Group>
                <TextInput leftSection={<IconSearch size={16} />} placeholder="Search users" />
                <Select data={["Placeholder 1", "Placeholder 2", "Placeholder 3"]} placeholder="Role: All" clearable />
            </Group>
            <MantineReactTable table={table} />
            {selectedUsers.length > 0 && (
                <FAB
                    onAssign={() => openRoleAssignModal()}
                    onRemove={() =>
                        openConfirmDeleteModal({
                            numUsers: selectedUsers.length,
                            onConfirm: () => {
                                const promises = selectedUsers.map((userId) => deleteUserAsync({ userId }));
                                settlePromises({
                                    promises,
                                    onSuccess: () => notify.show.success({ message: "Users deleted" }),
                                    onSomeFailed: () => notify.show.warn({ message: "Some users were not deleted" }),
                                    onAllFailed: () => notify.show.error({ message: "Could not delete user(s)" }),
                                }).catch(logger.error);
                            },
                        })
                    }
                    onClear={() => setRowSelection({})}
                />
            )}
        </Stack>
    );
};

const Page = () => {
    return (
        <Content paper>
            <Content.Heading backable>
                <Title order={2} fw="normal">
                    Users
                </Title>
                <Button
                    variant="default"
                    leftSection={<IconPlus size={16} />}
                    onClick={() => notify.show.info({ message: "Not implemented!" })}
                >
                    Invite User
                </Button>
                <Button variant="default" onClick={() => notify.show.info({ message: "Not implemented!" })}>
                    Import Users
                </Button>
            </Content.Heading>
            <UsersDataTable />
        </Content>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/organization/users")({
    component: Page,
    loader: () =>
        Promise.allSettled([
            queryClient.prefetchInfiniteQuery(infiniteUserQueryOptions),
            queryClient.prefetchInfiniteQuery(rolesQuery),
            queryClient.ensureQueryData(groupsQueryOptions.rootGroup),
            queryClient.ensureQueryData(topicsQueryOptions.rootTopic),
        ]),
});

const rolesQuery = createOffsetBasedGqlQueryOptions({
    queryKey: qk("roles", "list"),
    select: ({ roles }) => roles,
    document: graphql(`
        query OrganizationRoles($limit: Int!, $offset: Int!) {
            roles(limit: $limit, offset: $offset) {
                id
                name
                type
            }
        }
    `),
});
