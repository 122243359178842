import { ActionIcon, Badge, Button, Group, rem, Select, Stack, Text, TextInput, Title } from "@mantine/core";
import { useField, useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { IconDownload, IconPlus, IconX } from "@tabler/icons-react";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
// @ts-ignore
import IntlTelInput from "intl-tel-input/reactWithUtils";
import { useAuth } from "react-oidc-context";
import { httpPostGraphql } from "shared/api/httpClient";
import { Content } from "shared/components/global/Content";
import { useAddEud, useRemoveEud, useResendVerifyEud, useUpdateUser, useVerifyEud } from "shared/components/organization/customer";
import { userQueryOptions, useUser, useUserEuds } from "shared/components/organization/users";
import { useUserId } from "shared/stores/oidc";
import { titlecase } from "shared/utils/fns";
import { logger } from "shared/utils/logger";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";
import { Cm, Language } from "src/gql/graphql";
import { match } from "ts-pattern";

const LanguageContainer = () => {
    const { userId } = useUserId();
    const { languages } = useLanguages();
    const { user } = useUser();

    const { updateUser } = useUpdateUser();

    return (
        <>
            <Title order={4}>Preferences</Title>
            <Select
                allowDeselect={false}
                label="Language preference"
                defaultValue={user?.language}
                data={languages.map((l) => ({ label: titlecase(l as string), value: l }))}
                w="min-content"
                miw={rem(300)}
                onChange={(_, opt) => {
                    updateUser({
                        userId,
                        firstName: user?.firstName ?? "",
                        lastName: user?.lastName ?? "",
                        language: opt.value as Language,
                    });
                }}
            />
        </>
    );
};

function openAddEudModal(ctx: "email" | "sms" | "voice") {
    modals.open({ title: `Add ${ctxToCmLabel(ctx)}`, children: <AddEudModal ctx={ctx} />, centered: true });
}

function openVerifyEudModal({ ctx, id, value }: { ctx: "email" | "sms" | "voice"; id: string; value: string }) {
    modals.open({
        title: `Verify ${ctxToCmLabel(ctx)}`,
        children: <VerifyEudModal eudId={id} eudValue={value} />,
        centered: true,
    });
}

const EditNameForm = () => {
    const userInfo = useUserId();
    const {
        data: { firstName, lastName, language },
    } = useSuspenseQuery({
        ...userQueryOptions(userInfo),
        select: (data) => ({ firstName: data?.user.firstName, lastName: data?.user.lastName, language: data?.user.language }),
    });

    const form = useForm({ mode: "uncontrolled", initialValues: { firstName: firstName ?? "", lastName: lastName ?? "" } });
    const { updateUser } = useUpdateUser();

    return (
        <form
            onSubmit={form.onSubmit((data) => {
                updateUser({
                    userId: userInfo.userId,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    language: language ?? Language.English,
                });
            })}
        >
            <TextInput label="First Name" w="min-content" miw={rem(200)} key={form.key("firstName")} {...form.getInputProps("firstName")} />
            <TextInput label="Last Name" w="min-content" miw={rem(200)} key={form.key("lastName")} {...form.getInputProps("lastName")} />
            <Button leftSection={<IconDownload size={16} />} mt="xs" type="submit" disabled={!form.isDirty()}>
                Save
            </Button>
        </form>
    );
};

const AddEudModal = ({ ctx }: { ctx: "email" | "sms" | "voice" }) => {
    const { userId } = useUserId();
    const { addEudAsync } = useAddEud();

    const form = useForm({
        initialValues: { value: "", name: "" },
        validate: { value: (value) => (value.trim().length < 1 ? `Enter a Valid ${ctxToCmLabel(ctx)}` : null) },
    });

    return (
        <form
            onSubmit={form.onSubmit((data) => {
                addEudAsync({
                    userId,
                    params: {
                        name: data.name == "" ? undefined : data.name,
                        value: data.value,
                        type: match(ctx)
                            .with("email", () => Cm.Email)
                            .with("sms", () => Cm.Sms)
                            .with("voice", () => Cm.Phone)
                            .exhaustive(),
                    },
                })
                    .then((data) => {
                        modals.closeAll();
                        const { id, value } = data.addEUD;
                        openVerifyEudModal({ ctx, id, value });
                    })
                    .catch(logger.error);
            })}
        >
            <Stack>
                <TextInput label="Name" placeholder="Enter name..." key={form.key("name")} {...form.getInputProps("name")} />
                <TextInput label={ctxToCmLabel(ctx)} withAsterisk key={form.key("value")} {...form.getInputProps("value")} />
                <Button w="100%" mt="md" type="submit">
                    Send verification code
                </Button>
            </Stack>
        </form>
    );
};

const VerifyEudModal = ({ eudId, eudValue }: { eudId: string; eudValue: string }) => {
    const { userId } = useUserId();
    const { verifyEudAsync } = useVerifyEud();
    const { resendVerifyEud } = useResendVerifyEud();

    const verifyField = useField({ initialValue: "", validate: (v) => (v.trim().length < 1 ? "Enter a code" : null) });

    return (
        <Stack>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    verifyField
                        .validate()
                        .then(() => verifyEudAsync({ userId, eudId, code: verifyField.getValue() }))
                        .then(modals.closeAll)
                        .catch(logger.error);
                }}
            >
                <Stack>
                    <Text>
                        We sent a code to <em>{eudValue}</em>. This code is only active for the next 15 minutes.
                    </Text>
                    <TextInput label="Enter Code" withAsterisk placeholder="Enter code..." {...verifyField.getInputProps()} />
                    <Button
                        variant="default"
                        w="100%"
                        onClick={(e) => {
                            e.preventDefault();
                            resendVerifyEud({ userId, eudID: eudId });
                        }}
                    >
                        Resend Code
                    </Button>
                    <Button w="100%" type="submit">
                        Confirm
                    </Button>
                </Stack>
            </form>
        </Stack>
    );
};

function isPhone(cm: Cm) {
    return [Cm.Phone, Cm.Sms].includes(cm);
}

function ctxToCmLabel(ctx: "email" | "sms" | "voice") {
    return match(ctx)
        .with("email", () => "Email")
        .with("sms", () => "SMS Phone Number")
        .with("voice", () => "Voice Phone Number")
        .exhaustive();
}

const EUDDetails = ({
    eud,
    notVerifiedOnClick,
}: {
    eud: {
        name?: string | null;
        type: Cm;
        value: string;
        id: string;
        isVerified?: boolean;
    };
    notVerifiedOnClick: (type: "email" | "sms" | "voice") => void;
}) => {
    const { userId } = useUserId();
    const { removeEud } = useRemoveEud();

    return (
        <Group align="flex-end">
            <Stack gap={0}>
                <Group>
                    {!!eud.name && <Text>{eud.name}</Text>}
                    {eud.type == Cm.Phone && <Text>(Phone)</Text>}
                    {eud.type == Cm.Sms && <Text>(SMS)</Text>}
                    {!eud.isVerified ? (
                        <Badge
                            color="red"
                            onClick={() => notVerifiedOnClick(eud.type == Cm.Phone ? "voice" : eud.type == Cm.Sms ? "sms" : "email")}
                            component="button"
                            style={{ cursor: "pointer" }}
                        >
                            Not Verified
                        </Badge>
                    ) : (
                        <Badge color="green">Verified</Badge>
                    )}
                </Group>
                {isPhone(eud.type) && <IntlTelInput disabled initialValue={eud.value} initOptions={{ initialCountry: "US" }} />}
                {!isPhone(eud.type) && <Text>{eud.value}</Text>}
            </Stack>
            <ActionIcon
                variant="transparent"
                color="text"
                onClick={() => {
                    removeEud({ userId, eudId: eud.id });
                }}
            >
                <IconX size={16} />
            </ActionIcon>
        </Group>
    );
};

const CommunicationMethodsForm = () => {
    const { userId } = useUserId();
    const { euds } = useUserEuds();
    const { resendVerifyEud } = useResendVerifyEud();

    const emailEUDs = euds.filter((eud) => eud.type == Cm.Email);
    const phoneEUDs = euds.filter((eud) => isPhone(eud.type));

    return (
        <Stack>
            <Title order={4}>Communication Methods</Title>
            <Title order={5}>Email</Title>
            {emailEUDs.map((eud) => (
                <EUDDetails
                    key={`${eud.type}${eud.name}${eud.value}`}
                    eud={eud}
                    notVerifiedOnClick={() => {
                        resendVerifyEud({ userId, eudID: eud.id });
                        openVerifyEudModal({ ctx: "email", value: eud.value, id: eud.id });
                    }}
                />
            ))}
            {emailEUDs.length < 3 && (
                <Button leftSection={<IconPlus size={16} />} variant="default" w="min-content" onClick={() => openAddEudModal("email")}>
                    Add Email
                </Button>
            )}
            <Title order={5}>Phone Numbers</Title>
            {phoneEUDs.map((eud) => (
                <EUDDetails
                    eud={eud}
                    key={`${eud.type}${eud.name}${eud.value}`}
                    notVerifiedOnClick={(type) => {
                        resendVerifyEud({ userId, eudID: eud.id });
                        openVerifyEudModal({ ctx: type, value: eud.value, id: eud.id });
                    }}
                />
            ))}
            {phoneEUDs.length < 3 && (
                <>
                    <Button leftSection={<IconPlus size={16} />} variant="default" w="min-content" onClick={() => openAddEudModal("sms")}>
                        Add Text/SMS Phone Number
                    </Button>
                    <Button leftSection={<IconPlus size={16} />} variant="default" w="min-content" onClick={() => openAddEudModal("voice")}>
                        Add Voice Phone Number
                    </Button>
                    <Button disabled leftSection={<IconPlus size={16} />} variant="default" w="min-content">
                        Add TTY/TDD
                    </Button>
                </>
            )}
        </Stack>
    );
};

export const ProfileContainer = () => {
    const auth = useAuth();

    return (
        <Content paper p="xl">
            <Content.Heading>
                <Title order={3} id="profile">
                    Profile Information
                </Title>
            </Content.Heading>
            <Title order={4}>Preferred Name</Title>
            <EditNameForm />
            <Title order={4}>Registered Email</Title>
            <Text>{auth.user?.profile.preferred_username}</Text>
            <a href={`${auth.settings.authority}/account`} target="_blank" style={{ width: "fit-content" }}>
                <Button variant="default">Edit Sign-in Information</Button>
            </a>
            <Link to="/logout">
                <Button color="red">Log out</Button>
            </Link>
            <CommunicationMethodsForm />
            <LanguageContainer />
        </Content>
    );
};

function useLanguages() {
    const {
        data: {
            customer: { languages },
        },
    } = useSuspenseQuery(languageQueryOptions);
    return { languages };
}

export const languageQueryOptions = queryOptions({
    queryKey: qk("customer", "languages"),
    queryFn: () => httpPostGraphql(languageQuery, {}),
});

const languageQuery = graphql(`
    query ProfileGetLanguages {
        customer {
            languages
        }
    }
`);
