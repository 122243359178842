import { Loader } from "@mantine/core";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { permissionCheck, usePermissions } from "shared/stores/oidc";
import { logger } from "shared/utils/logger";
import { Permission } from "src/gql/graphql";

/** @public */
export const Route = createFileRoute("/_auth/organization/")({
    component: () => {
        const nav = useNavigate();
        const permissions = usePermissions();

        useEffect(() => {
            if (permissionCheck(permissions, Permission.GroupSubgroupManage)) nav({ to: "/organization/groups" }).catch(logger.error);
            else if (permissionCheck(permissions, Permission.TopicCategoryManage)) nav({ to: "/organization/topics" }).catch(logger.error);
            else nav({ to: "/organization/users" }).catch(logger.error);
        }, []);

        return <Loader />;
    },
});
