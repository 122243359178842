import {
    ActionIcon,
    Anchor,
    Badge,
    Button,
    Checkbox,
    Divider,
    Group,
    List,
    Paper,
    rem,
    Select,
    Space,
    Stack,
    Text,
    TextInput,
    Title,
} from "@mantine/core";
import { useField, useForm, zodResolver } from "@mantine/form";
import { modals } from "@mantine/modals";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons-react";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, redirect, useNavigate } from "@tanstack/react-router";
import { httpPostGraphql } from "shared/api/httpClient";
import { AddressForm } from "shared/components/address/AddressForm";
import { AddressFormProvider, addressFormStateSchema, useAddressForm } from "shared/components/address/context";
import { Content } from "shared/components/global/Content";
import { PhoneNumber } from "shared/components/global/PhoneNumber";
import { Section, SectionProvider } from "shared/components/global/Section";
import { userQueryOptions } from "shared/components/organization/users";
import { TopicTreeSelect } from "shared/components/topic/TopicTreeSelect";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { isSupport } from "shared/hooks/useIsSupport";
import { getUserId, useUserId } from "shared/stores/oidc";
import { arr } from "shared/utils/array";
import { fail, titlecase, to, uniqueBy } from "shared/utils/fns";
import { maybe } from "shared/utils/maybe";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { nonemptyString } from "shared/utils/zod";
import { graphql } from "src/gql";
import { Cm, Language } from "src/gql/graphql";
import { queryClient } from "src/queryClient";
import { match, P } from "ts-pattern";
import { nativeEnum, z } from "zod";

const registerFormStateSchema = z.object({
    firstName: nonemptyString(),
    lastName: nonemptyString(),
    language: nativeEnum(Language),
    didConsentToPrivacyPolicy: z.literal(true, { message: "Required" }),
    didConsentToEula: z.literal(true, { message: "Required" }),
});

const Component = () => {
    const nav = useNavigate();
    const userInfo = useUserId();
    const { data, refetch } = useSuspenseQuery(userQueryOptions(userInfo));
    const user = data?.user;
    const { data: customerData } = useSuspenseQuery(queries.customer);
    const { mutateAsync: createEud } = useGraphqlMutation({ document: mutations.createEud, onSuccess: () => refetch() });
    const { mutateAsync: deleteEud } = useGraphqlMutation({ document: mutations.deleteEud, onSuccess: () => refetch() });
    const { mutateAsync: updateAddress } = useGraphqlMutation({ document: mutations.updateAddress, onSuccess: () => refetch() });
    const { mutateAsync: deleteAddress } = useGraphqlMutation({ document: mutations.deleteAddress, onSuccess: () => refetch() });
    const { mutateAsync: subscribeToTopic } = useGraphqlMutation({ document: mutations.subscribeToTopic, onSuccess: () => refetch() });
    const { mutateAsync: unsubscribeFromTopic } = useGraphqlMutation({
        document: mutations.unsubscribeFromTopic,
        onSuccess: () => refetch(),
    });
    const { mutateAsync: setIsEudInformational } = useGraphqlMutation({
        document: mutations.setIsEudInformational,
        onSuccess: () => refetch(),
    });
    const { mutateAsync: registerAccount } = useGraphqlMutation({ document: mutations.registerAccount, onSuccess: () => refetch() });
    const { mutateAsync: createAddress } = useGraphqlMutation({ document: mutations.createAddress, onSuccess: () => refetch() });

    const form = useForm<z.infer<typeof registerFormStateSchema>>({
        validate: zodResolver(registerFormStateSchema),
    });

    const addressForm = useAddressForm({
        mode: "uncontrolled",
        validate: zodResolver(addressFormStateSchema),
    });

    // #region handlers

    function handleAddAddress() {
        addressForm.reset();

        modals.open({
            title: "Add Address",
            children: (
                <Stack>
                    <AddressFormProvider form={addressForm}>
                        <AddressForm />
                    </AddressFormProvider>

                    <Divider />

                    <Button
                        w="100%"
                        leftSection={<IconPlus size={16} />}
                        onClick={() => {
                            if (!addressForm.isValid()) return;
                            createAddress({ userId: userInfo.userId, ...addressForm.getValues() })
                                .then(() => modals.closeAll())
                                .catch(notify.catch);
                        }}
                    >
                        Add
                    </Button>
                </Stack>
            ),
        });
    }

    function handleEditAddress(id: string) {
        addressForm.setErrors({}); // reset errors
        maybe(user?.addresses.find((it) => it.id == id))?.run(addressForm.setValues) ?? fail("Address index out of bounds");

        modals.open({
            title: "Edit Address",
            children: (
                <Stack>
                    <AddressFormProvider form={addressForm}>
                        <AddressForm />
                    </AddressFormProvider>
                    <Button
                        w="100%"
                        onClick={() => {
                            if (addressForm.validate().hasErrors) return;
                            const updates = addressForm.getValues();
                            if ("id" in updates) delete updates.id;

                            updateAddress({ userId: userInfo.userId, addressId: id, updates })
                                .then(() => modals.closeAll())
                                .catch(notify.catch);
                        }}
                    >
                        Done
                    </Button>
                </Stack>
            ),
        });
    }

    function handleDeleteAddress(addressId: string) {
        modals.openConfirmModal({
            title: "Delete Address",
            children: <Text size="sm">Are you sure you want to delete this address?</Text>,
            labels: { confirm: "Delete", cancel: "Cancel" },
            confirmProps: { color: "red", leftSection: <IconTrash size={16} /> },
            onConfirm() {
                deleteAddress({ userId: userInfo.userId, addressId }).catch(notify.catch);
            },
        });
    }

    function handleAddSmsEud() {
        const Modal = () => {
            const form = useForm<{ name?: string; value: string }>({
                validate: zodResolver(
                    z.object({
                        name: z.string().optional(),
                        value: nonemptyString(),
                    }),
                ),
            });

            return (
                <Stack>
                    <TextInput label="Name" {...form.getInputProps("name")} />
                    <PhoneNumber
                        withAsterisk
                        label="SMS Phone Number"
                        onClearError={() => form.clearFieldError("value")}
                        onSetError={(err: string) => form.setFieldError("value", err)}
                        onSetValue={(value) => form.setFieldValue("value", value)}
                        {...form.getInputProps("value")}
                    />
                    <Divider />
                    <Button
                        onClick={() => {
                            if (!form.isValid()) return;
                            createEud({ userId: userInfo.userId, type: Cm.Sms, ...form.getValues() })
                                .then(() => modals.closeAll())
                                .catch(notify.catch);
                        }}
                        w="100%"
                    >
                        Confirm
                    </Button>
                </Stack>
            );
        };

        modals.open({
            title: "Add SMS Phone Number",
            children: <Modal />,
        });
    }

    function handleAddVoiceEud() {
        const Modal = () => {
            const form = useForm<{ name: string; value: string }>({
                validate: zodResolver(
                    z.object({
                        name: z.string().optional(),
                        value: nonemptyString(),
                    }),
                ),
            });

            return (
                <Stack>
                    <TextInput label="Name" {...form.getInputProps("name")} />
                    <PhoneNumber
                        withAsterisk
                        label="SMS Phone Number"
                        onClearError={() => form.clearFieldError("value")}
                        onSetError={(err: string) => form.setFieldError("value", err)}
                        onSetValue={(value) => form.setFieldValue("value", value)}
                        {...form.getInputProps("value")}
                    />
                    <Divider />
                    <Button
                        onClick={() => {
                            if (!form.isValid()) return;
                            createEud({ userId: userInfo.userId, type: Cm.Phone, ...form.getValues() })
                                .then(() => modals.closeAll())
                                .catch(notify.catch);
                        }}
                        w="100%"
                    >
                        Confirm
                    </Button>
                </Stack>
            );
        };

        modals.open({
            title: "Add Voice Phone Number",
            children: <Modal />,
        });
    }

    function handleBeginEudVerification(eudId: string) {
        const Modal = () => {
            const code = useField({ initialValue: "" });
            const { mutate: resendCode } = useGraphqlMutation({
                document: mutations.resendEudVerificationCode,
                onSuccess: () => notify.show.success({ message: "Resent code" }),
            });

            function handleValidate() {
                httpPostGraphql(mutations.verifyEud, { userId: userInfo.userId, eudId, code: code.getValue() })
                    .then(() => {
                        modals.closeAll();
                        void refetch();
                    })
                    .catch(() => {
                        code.setError("Invalid Code");
                    });
            }

            return (
                <Stack>
                    <TextInput {...code.getInputProps()} />
                    <Button variant="default" w="100%" onClick={() => resendCode({ userId: userInfo.userId, eudId })}>
                        Resend Code
                    </Button>
                    <Button w="100%" onClick={handleValidate}>
                        Confirm
                    </Button>
                </Stack>
            );
        };

        modals.open({
            title: "Verify Device",
            children: <Modal />,
        });
    }

    function handleRegister() {
        if (form.validate().hasErrors) return;

        registerAccount({ userId: userInfo.userId, ...form.getValues() })
            .then(() => notify.show.success({ message: "Your Account has been Registered!" }))
            .then(() => qk.invalidate("user", "id", userInfo.userId))
            .then(() => nav({ to: "/" }))
            .catch(notify.catch);
    }

    // #endregion

    function formatEud(opts: { type: Cm; name?: string; value: string }): string {
        return match(opts)
            .with({ type: Cm.Ios, name: P.string }, ({ name }) => `iOS Push Notifications (${name})`)
            .with({ type: Cm.Ios }, () => `iOS Push Notifications`)
            .with({ type: Cm.Android, name: P.string }, ({ name }) => `Android Push Notifications (${name})`)
            .with({ type: Cm.Android }, () => `iOS Push Notifications`)
            .with({ type: P.union(Cm.Sms, Cm.Phone) }, ({ type, value }) => `${titlecase(type)} ${value}`)
            .with({ name: P.string }, ({ type, name }) => `${titlecase(type)} (${name})`)
            .otherwise(({ type, value }) => titlecase(type) + " - " + value);
    }

    return (
        <Content my="xl">
            <Content.Heading>
                <Title order={3}>Complete Account Set Up</Title>
            </Content.Heading>

            <SectionProvider>
                <Section label={"Topic Subscriptions"}>
                    <Text>Subscribe to topics to receive messages regarding said topics.</Text>

                    <TopicTreeSelect
                        disableRequired
                        values={user?.topics ?? []}
                        onTopicChange={({ id: topicId }, checked) => {
                            if (checked) subscribeToTopic({ userId: userInfo.userId, topicId }).catch(notify.catch);
                            else unsubscribeFromTopic({ userId: userInfo.userId, topicId }).catch(notify.catch);
                        }}
                    />

                    <Text>Go to your profile to update after registration.</Text>
                </Section>
                <Section label={"Location(s)"}>
                    {!!user?.addresses.length && (
                        <List>
                            {user.addresses.map((address) => (
                                <List.Item key={address.id}>
                                    <Group gap={0}>
                                        {`${address.street} ${address.street2 ?? ""} ${address.city}, ${address.state} ${address.zip} (${address.name})`}
                                        <Space w={rem(16)} />
                                        <ActionIcon size="xs" variant="transparent" onClick={() => handleEditAddress(address.id)}>
                                            <IconEdit size={16} />
                                        </ActionIcon>
                                        <ActionIcon size="xs" c="red" variant="transparent" onClick={() => handleDeleteAddress(address.id)}>
                                            <IconTrash size={16} />
                                        </ActionIcon>
                                    </Group>
                                </List.Item>
                            ))}
                        </List>
                    )}
                    <Text>Add in up to 5 addresses to recieve notifications pertaining to the address locations.</Text>
                    <Button variant="default" disabled={(user?.addresses.length ?? 0) >= 5} onClick={handleAddAddress}>
                        Add Address
                    </Button>
                </Section>
                <Section label={"Profile Information"}>
                    <List>
                        {user?.euds
                            .filter((it) => it.type == Cm.Sms)
                            .map((eud) => (
                                <List.Item key={eud.id}>
                                    <Group gap={"xs"}>
                                        <Text>{formatEud(eud)}</Text>
                                        {eud.isVerified && (
                                            <Badge color="green" variant="light" radius={"sm"}>
                                                Verified
                                            </Badge>
                                        )}
                                        {!eud.isVerified && (
                                            <Button
                                                variant="transparent"
                                                size="compact-sm"
                                                onClick={() => handleBeginEudVerification(eud.id)}
                                            >
                                                Verify
                                            </Button>
                                        )}
                                        <ActionIcon
                                            c="red"
                                            variant="transparent"
                                            size={"xs"}
                                            onClick={() => void deleteEud({ userId: userInfo.userId, eudId: eud.id }).catch(notify.catch)}
                                        >
                                            <IconTrash size={16} />
                                        </ActionIcon>
                                    </Group>
                                </List.Item>
                            ))}
                    </List>
                    <Button variant="default" leftSection={<IconPlus size={16} />} onClick={handleAddSmsEud}>
                        Add SMS/Text Phone Number
                    </Button>
                    <List>
                        {user?.euds
                            .filter((it) => it.type == Cm.Phone)
                            .map((eud) => (
                                <List.Item key={eud.id}>
                                    <Group gap={"xs"}>
                                        <Text>{formatEud(eud)}</Text>
                                        {eud.isVerified && (
                                            <Badge color="green" variant="light" radius={"sm"}>
                                                Verified
                                            </Badge>
                                        )}
                                        {!eud.isVerified && (
                                            <Button
                                                variant="transparent"
                                                size="compact-sm"
                                                onClick={() => handleBeginEudVerification(eud.id)}
                                            >
                                                Verify
                                            </Button>
                                        )}
                                        <ActionIcon
                                            c="red"
                                            variant="transparent"
                                            size={"xs"}
                                            onClick={() => void deleteEud({ userId: userInfo.userId, eudId: eud.id }).catch(notify.catch)}
                                        >
                                            <IconTrash size={16} />
                                        </ActionIcon>
                                    </Group>
                                </List.Item>
                            ))}
                    </List>
                    <Button variant="default" leftSection={<IconPlus size={16} />} onClick={handleAddVoiceEud}>
                        Add Voice Phone Number
                    </Button>
                    <Select
                        searchable
                        withAsterisk
                        label="Language Preference"
                        data={arr(Object.entries(Language).map(([k, v]) => ({ label: k, value: v })))
                            .morph((it) => [{ label: "English", value: Language.English }].concat(it).filter(uniqueBy("value")))
                            .take()}
                        {...form.getInputProps("language")}
                    />
                    <TextInput label="First Name" withAsterisk {...form.getInputProps("firstName")} />
                    <TextInput label="Last Name" withAsterisk {...form.getInputProps("lastName")} />

                    <Title order={4}>Informational Notifications</Title>
                    <Text>Urgent messages will be sent to all channels regardless of preference.</Text>
                    {user?.euds.map((eud, i) => (
                        <Checkbox
                            key={eud.value + i}
                            label={formatEud(eud)}
                            disabled={!eud.isVerified}
                            checked={eud.isDefault}
                            onChange={(e) =>
                                void setIsEudInformational({
                                    userId: userInfo.userId,
                                    eudId: eud.id,
                                    isInformational: e.target.checked,
                                }).catch(notify.catch)
                            }
                        />
                    ))}

                    <Paper p="md" withBorder>
                        Mobile App Link
                    </Paper>

                    <Divider />

                    <Checkbox
                        label={
                            <Text>
                                By checking this box, you consent to receive notifications and alerts from{" "}
                                <strong>{customerData.name}</strong> via Text Message/Email/Phone Call. These notifications may include
                                emergency alerts, service updates, and important announcements. Message and data rates may apply. You can
                                opt out at any time by replying "STOP." For more details, please review our{" "}
                                <Anchor href="/account/privacy" c="primary">
                                    privacy policy
                                </Anchor>
                                .
                            </Text>
                        }
                        {...form.getInputProps("didConsentToPrivacyPolicy", { type: "checkbox" })}
                    />
                    <Checkbox
                        label={
                            <Text>
                                By creating this account you agree to the{" "}
                                <Anchor href="/account/tos" c="primary">
                                    Terms of Service
                                </Anchor>{" "}
                                and the{" "}
                                <Anchor href="/account/eula" c="primary">
                                    User Agreement
                                </Anchor>{" "}
                                of Bryx MNS.
                            </Text>
                        }
                        {...form.getInputProps("didConsentToEula", { type: "checkbox" })}
                    />
                </Section>
            </SectionProvider>
            <Button onClick={handleRegister}>Register</Button>
        </Content>
    );
};

const queries = {
    customer: queryOptions({
        queryKey: ["register", "customer"],
        queryFn: () =>
            httpPostGraphql(
                graphql(`
                    query AccountRegisterCustomer {
                        customer {
                            name
                        }
                    }
                `),
                {},
            ),
        select: to("customer"),
    }),
    euds: (params: { userId: string }) =>
        queryOptions({
            queryKey: ["register", "euds"],
            queryFn: () =>
                httpPostGraphql(
                    graphql(`
                        query AccountRegisterEUDs($userId: UUID!) {
                            getUserEUDs(userId: $userId) {
                                id
                                name
                                value
                                type
                                isDefault
                            }
                        }
                    `),
                    params,
                ),
            select: to("getUserEUDs"),
        }),
};

const mutations = {
    registerAccount: graphql(`
        mutation RegisterAccount($userId: UUID!, $firstName: String!, $lastName: String!, $language: Language!) {
            updateUser(userId: $userId, params: { firstName: $firstName, lastName: $lastName, language: $language }) {
                id
            }
        }
    `),
    subscribeToTopic: graphql(`
        mutation RegisterAddUserToTopic($userId: UUID!, $topicId: UUID!) {
            addUserToTopic(userId: $userId, topicId: $topicId) {
                id
            }
        }
    `),
    unsubscribeFromTopic: graphql(`
        mutation RegisterRemoveUserFromTopic($userId: UUID!, $topicId: UUID!) {
            removeUserFromTopic(userId: $userId, topicId: $topicId) {
                id
            }
        }
    `),
    createEud: graphql(`
        mutation RegisterCreateEud($userId: UUID!, $name: String, $type: CM!, $value: String!) {
            addEUD(userId: $userId, eudParams: { name: $name, type: $type, value: $value }) {
                id
            }
        }
    `),
    updateAddress: graphql(`
        mutation RegisterUpdateAddress($userId: UUID!, $addressId: UUID!, $updates: UserAddressInput!) {
            updateUserAddress(userId: $userId, addressId: $addressId, address: $updates) {
                id
            }
        }
    `),
    deleteAddress: graphql(`
        mutation RegisterDeleteAddress($userId: UUID!, $addressId: UUID!) {
            removeUserAddress(userId: $userId, addressId: $addressId)
        }
    `),
    deleteEud: graphql(`
        mutation RegisterDeleteEud($userId: UUID!, $eudId: UUID!) {
            removeEUD(userId: $userId, eudId: $eudId)
        }
    `),
    verifyEud: graphql(`
        mutation RegisterVerifyEud($userId: UUID!, $eudId: UUID!, $code: String!) {
            verifyEUD(userId: $userId, eudId: $eudId, verificationCode: $code) {
                id
            }
        }
    `),
    resendEudVerificationCode: graphql(`
        mutation RegisterResendVerificationCode($userId: UUID!, $eudId: UUID!) {
            resendEUDVerificationCode(userId: $userId, eudId: $eudId) {
                id
            }
        }
    `),
    setIsEudInformational: graphql(`
        mutation RegisterMarkEudAsInformational($userId: UUID!, $eudId: UUID!, $isInformational: Boolean!) {
            setDefaultEud(userId: $userId, eudId: $eudId, isDefault: $isInformational) {
                id
            }
        }
    `),
    createAddress: graphql(`
        mutation RegisterCreateAddress(
            $userId: UUID!
            $name: String!
            $city: String!
            $state: String!
            $street: String!
            $street2: String
            $zip: String!
        ) {
            addUserAddress(
                userId: $userId
                address: { name: $name, city: $city, state: $state, street: $street, street2: $street2, zip: $zip }
            ) {
                id
            }
        }
    `),
};

/** @public */
export const Route = createFileRoute("/account/register")({
    beforeLoad: async ({ context }) => {
        const auth = await context.auth;
        if (!auth.isAuthenticated) throw redirect({ to: "/login", search: { redirect: location.href } });
        const data = await queryClient.ensureQueryData({
            ...userQueryOptions({ userId: getUserId(auth), isSupport: isSupport(auth) }),
        });
        if (!!data?.user.firstName) throw redirect({ to: "/" });
    },
    component: Component,
});
