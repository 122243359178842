import { Button, Group, Stack, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconPlus } from "@tabler/icons-react";
import { Suspense } from "react";
import { BadgeItem } from "shared/components/global/BadgeItem";
import { ComposeFormProvider, useComposeFormContext } from "shared/components/message/compose/context";
import { TopicTreeSelect } from "shared/components/topic/TopicTreeSelect";
import { useFormState } from "shared/hooks/useFormState";
import { useFormSubscription } from "shared/hooks/useFormSubscription";

export const AudienceTopicEditor = () => {
    const form = useComposeFormContext();
    const topics = useFormSubscription(form, "audience.topics");

    function handleDeleteTopic(idx: number) {
        form.setFieldValue("audience.topics", (prev) => prev.toSpliced(idx, 1));
    }

    return (
        <Stack>
            <Text fw="bold">Topics</Text>
            <Button
                variant="default"
                leftSection={<IconPlus size={16} />}
                onClick={() =>
                    modals.open({
                        title: "Select Topics",
                        children: (
                            <ComposeFormProvider form={form}>
                                <Modal />
                            </ComposeFormProvider>
                        ),
                    })
                }
            >
                Add Topics
            </Button>
            <Group>
                {topics.map((tpc, i) => (
                    <BadgeItem size="lg" key={tpc.id + i} onDelete={() => handleDeleteTopic(i)}>
                        {tpc.name}
                    </BadgeItem>
                ))}
            </Group>
        </Stack>
    );
};

const Modal = () => {
    const form = useComposeFormContext();
    const [values, handleChange] = useFormState(form, "audience.topics");

    return (
        <Suspense>
            <TopicTreeSelect values={values} onChange={handleChange} />
        </Suspense>
    );
};
