import { Button, Divider, Group, Stack, Text } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useField } from "@mantine/form";
import { IconSend } from "@tabler/icons-react";
import { Section, SectionProvider } from "shared/components/global/Section";

export const SendPanel = ({ onClose, onSend }: { onClose?: () => unknown; onSend?: (conf: { expireAt: string | null }) => unknown }) => {
    const field = useField<Date | null>({ initialValue: null });

    return (
        <Stack>
            <Text>You are about to send a message. Are you sure you want to continue?</Text>
            <DateTimePicker clearable label="Expiration" placeholder="Never" {...field.getInputProps()} />
            <SectionProvider>
                <Section label={"Recipients"}>
                    <div />
                </Section>
            </SectionProvider>
            <Divider />
            <Group justify="flex-end">
                <Button variant="default" onClick={() => onClose?.()}>
                    Cancel
                </Button>
                <Button
                    leftSection={<IconSend size={16} />}
                    onClick={() => {
                        onSend?.({ expireAt: field.getValue()?.valueOf().toString() ?? null });
                        onClose?.();
                    }}
                >
                    Send
                </Button>
            </Group>
        </Stack>
    );
};
