import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { httpPostGraphql } from "shared/api/httpClient";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { useUserId } from "shared/stores/oidc";
import { qk } from "shared/utils/qk";
import { createOffsetBasedGqlQueryOptions } from "shared/utils/query";
import { graphql } from "src/gql";
import { GroupLikeFragment } from "src/gql/graphql";

const userQuery = graphql(`
    query User($id: UUID!) {
        user(userId: $id) {
            id
            firstName
            lastName
            language
            topics {
                id
                name
            }
            groups {
                ...GroupLike
            }
            topics {
                ...TopicLike
            }
            clientPermissions {
                customer
                group {
                    id
                    permissions
                }
                topic {
                    id
                    permissions
                }
            }
            euds {
                id
                name
                type
                value
                isVerified
                isDefault
            }
            addresses {
                name
                city
                id
                state
                street
                street2
                zip
            }
        }
    }
`);

export function useUser(id?: string) {
    const { userId, isSupport } = useUserId();
    const { data } = useSuspenseQuery({ ...userQueryOptions({ userId, isSupport }) });
    if (isSupport && !id) return { user: undefined };
    return { user: data?.user };
}

// TODO: implement UI for support users to view other users's groups
export function useUserGroups(id?: string) {
    const { userId, isSupport } = useUserId();
    if (isSupport && !id) return { groups: [] };
    const { data: groups } = useSuspenseQuery({
        ...userQueryOptions({ userId: id ?? userId, isSupport }),
        select: (data) => data?.user.groups ?? [],
    });
    return { groups: groups as GroupLikeFragment[] };
}

export function useUserEuds(id?: string) {
    const { userId, isSupport } = useUserId();
    if (isSupport && !id) return { euds: [] };
    const { data: euds } = useSuspenseQuery({
        ...userQueryOptions({ userId: id ?? userId, isSupport }),
        select: (data) => data?.user.euds ?? [],
    });
    return { euds };
}

export function userQueryOptions({ userId, isSupport }: { userId: string; isSupport: boolean }) {
    return queryOptions({
        queryKey: qk("user", "id", userId),
        staleTime: 5 * 60 * 60, // 5 minutes
        queryFn: async () => {
            if (isSupport) return null;
            return httpPostGraphql(userQuery, { id: userId });
        },
    });
}

const infiniteUserQuery = graphql(`
    query Users($limit: Int!, $offset: Int!) {
        users(limit: $limit, offset: $offset) {
            id
            firstName
            lastName
            euds {
                type
                value
            }
            roles {
                id
                role {
                    id
                    name
                    type
                }
                group {
                    id
                }
                topic {
                    id
                }
            }
            groups {
                name
            }
            lastLogin
        }
    }
`);

export const infiniteUserQueryOptions = createOffsetBasedGqlQueryOptions({
    queryKey: qk("user", "list"),
    select: ({ users }) =>
        users.map((user) => ({
            userId: user.id,
            contactInfo: { name: `${user.firstName ?? ""} ${user.lastName ?? ""}`, euds: user.euds },
            roles: user.roles,
            groups: user.groups,
            lastActive: user.lastLogin,
        })),
    document: infiniteUserQuery,
});

// NOTE: QueryKeys are not handled here since these operations are intended to be used
// many times in a bulk operation. When we get real bulk mutation endpoints the query keys
// should be moved here
export function useAssignGroupRole() {
    const { mutateAsync: assignGroupRoleAsync } = useGraphqlMutation(mutations.assignGroupRole);
    return { assignGroupRoleAsync };
}

export function useAssignTopicRole() {
    const { mutateAsync: assignTopicRoleAsync } = useGraphqlMutation(mutations.assignTopicRole);
    return { assignTopicRoleAsync };
}

export function useAssignCustomerRole() {
    const { mutateAsync: assignCustomerRoleAsync } = useGraphqlMutation(mutations.assignCustomerRole);
    return { assignCustomerRoleAsync };
}

export function useUnassignRole() {
    const { mutateAsync: unassignRoleAsync } = useGraphqlMutation({
        document: mutations.unassignRole,
        onSuccess: async () => {
            await qk.invalidate("user");
        },
    });
    return { unassignRoleAsync };
}

export function useDeleteUser() {
    const { mutateAsync: deleteUserAsync } = useGraphqlMutation(mutations.deleteUser);
    return { deleteUserAsync };
}

const mutations = {
    deleteUser: graphql(`
        mutation DeleteUser($userId: UUID!) {
            archiveUser(userId: $userId)
        }
    `),
    assignCustomerRole: graphql(`
        mutation AssignCustomerRole($userId: UUID!, $roleId: UUID!) {
            assignCustomerRole(params: { roleId: $roleId, userId: $userId }) {
                id
            }
        }
    `),
    assignGroupRole: graphql(`
        mutation AssignGroupRole($userId: UUID!, $roleId: UUID!, $groupId: UUID!) {
            assignGroupRole(params: { roleId: $roleId, userId: $userId, groupId: $groupId }) {
                id
            }
        }
    `),
    assignTopicRole: graphql(`
        mutation AssignTopicRole($userId: UUID!, $roleId: UUID!, $topicId: UUID!) {
            assignTopicRole(params: { roleId: $roleId, userId: $userId, topicId: $topicId }) {
                id
            }
        }
    `),
    unassignRole: graphql(`
        mutation UnassignRole($userId: UUID!, $userRoleId: UUID!) {
            unassignRole(userId: $userId, userRoleId: $userRoleId)
        }
    `),
};
