import { QueryKey } from "@tanstack/react-query";
import { queryClient } from "src/queryClient";

/**
 * Want to introduce a new query key? Just update me!
 *
 * @private
 */
type Config = {
    messages: [
        "inbox",
        "sent",
        "draft",
        "scheduled",
        { detail: [string]; composer: { message: [string]; groups: { search: [string] } }; id: [string] },
    ];
    topicTree: ["list"];
    templates: ["list", { detail: [string] }];
    document: [string, { presignedUrl: [string] }];
    map: { customer: ["bounds", { geofiltersWithin: [string] }] };
    compose: ["senders"];
    sandbox: ["drafts"];
    user: ["list", { id: [string] }];
    roles: ["list"];
    group: ["rootGroup", { id: { [_ in string]: ["members", "children", "details"] } }];
    topic: ["rootTopic", { id: { [_ in string]: ["members", "children", "details"] } }];
    customer: ["languages"];
    settings: ["rootGroup", "rootTopic"];
};

// *** //

/** @private */
type Resolved<T, Ctx extends ReadonlyArray<string> = []> = T extends readonly (infer Each)[]
    ? Each extends Each
        ? Resolved<Each, Ctx>
        : never
    : T extends string
      ? [...Ctx, T]
      : T extends Record<infer Key extends string, unknown>
        ? Key extends Key
            ? Resolved<T[Key], [...Ctx, Key]>
            : never
        : never;

/** @private */
type Fuzzy<T, Ctx extends ReadonlyArray<string> = []> = T extends readonly [infer Head extends string, ...infer Rest]
    ? [...Ctx, Head] | Fuzzy<Rest, [...Ctx, Head]>
    : never;

/**
 * A predetermined subtype of {@link QueryKey} for use throughout EMNS. Standardized query keys allow for cache reuse with confience that there won't be typos or unexpected keys supplied.
 */
export type EmnsQK = Resolved<Config> & QueryKey; // eslint-disable-line @typescript-eslint/naming-convention

export const qk = Object.assign(
    /**
     * Constructs a type-safe {@link QueryKey} via {@link EmnsQK}.
     */
    (...args: EmnsQK) => args,
    {
        /** Invalidates the given partial {@link EmnsQK} */
        invalidate: (...queryKey: Fuzzy<Resolved<Config>>) => queryClient.invalidateQueries({ queryKey, exact: false }),
    },
);
