import { Accordion, Anchor, Loader, Paper } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { Content } from "shared/components/global/Content";
import {
    GroupDetailsForm,
    groupsQueryOptions,
    MemberDetails,
    useAddParentToGroup,
    useRemoveParentFromGroup,
    useUpdateGroup,
} from "shared/components/organization/groups";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { usePermissionCheck } from "shared/stores/oidc";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { GroupLikeFragment, MemberLikeFragment, Permission } from "src/gql/graphql";
import { queryClient } from "src/queryClient";
import { router } from "src/router";

// const SenderDetails = ({ senders }: { senders: GroupDetailsQuery["group"]["senders"] }) => {
//     // TODO
// };

// const TemplateDetails = ({ templates }: { templates: GroupDetailsQuery["group"]["template"] }) => {
//     // TODO
// };

const GroupDetailsContainer = () => {
    const isMobile = useIsMobile();
    const { groupId } = Route.useParams();
    const canViewMembership = usePermissionCheck(Permission.GroupUsersView, groupId);
    const canChangeMembership = usePermissionCheck(Permission.GroupUsersManage, groupId);

    const {
        data: { rootGroup },
    } = useSuspenseQuery(groupsQueryOptions.rootGroup);
    const {
        data: { group },
    } = useSuspenseQuery(groupsQueryOptions.groupDetails(groupId));
    const { data } = useQuery({ ...groupsQueryOptions.members(groupId), enabled: canViewMembership });

    const { updateGroupAsync } = useUpdateGroup();
    const { addParentAsync } = useAddParentToGroup();
    const { removeParentAsync } = useRemoveParentFromGroup();

    const form = useForm<GroupLikeFragment>({
        initialValues: {
            ...group,
        },
        validate: {
            name: (v) => (v.length == 0 ? "Invalid name" : null),
            parents: (v) => (group.id != rootGroup.id && v.length == 0 ? "Group must have at least one parent" : null),
        },
    });

    const { getValues, reset } = form;

    function handleSubmit(data: GroupLikeFragment) {
        const promises: Promise<unknown>[] = [];
        if (form.isDirty("name") || form.isDirty("internalJoinable")) {
            promises.push(updateGroupAsync({ internalJoinable: data.internalJoinable, name: data.name, groupId: data.id }));
        }
        if (form.isDirty("parents")) {
            const newParents = getValues().parents.filter((p) => !group.parents.some((g) => g.id == p.id));
            const removedParents = group.parents.filter((p) => !getValues().parents.some((g) => g.id == p.id));
            if (newParents.length) newParents.forEach((p) => promises.push(addParentAsync({ groupId: group.id, parentId: p.id })));
            if (getValues().parents.length === 0) promises.push(Promise.reject("Group must have at least one parent"));
            else if (removedParents.length)
                removedParents.forEach((p) => promises.push(removeParentAsync({ groupId: group.id, parentId: p.id })));
        }

        Promise.all(promises)
            .then(async () => {
                await Promise.all([qk.invalidate("group"), qk.invalidate("user")]);
                notify.show.success({ message: "Updated group details" });
                form.setInitialValues({
                    ...getValues(),
                });
                reset();
            })
            .catch(logger.error);
    }

    return (
        <Paper p={!isMobile ? "sm" : undefined} h="100%" mah="85dvh" style={{ flexGrow: 1, overflowY: "auto" }}>
            <Accordion chevronPosition="left" w="100%" multiple defaultValue={["group", "members"]}>
                <Content.Heading backable p={0} gap={0}>
                    <Anchor onClick={() => router.history.back()} c="var(--mantine-color-text)">
                        Back
                    </Anchor>
                </Content.Heading>
                <GroupDetailsForm group={group} handleSubmit={handleSubmit} form={form} />
                {canViewMembership && !!data?.group.members ? (
                    <MemberDetails
                        members={data.group.members as MemberLikeFragment[]}
                        groupId={group.id}
                        canViewMembership={canViewMembership}
                        canChangeMembership={canChangeMembership}
                        isCreateForm={false}
                    />
                ) : (
                    <Loader />
                )}
            </Accordion>
        </Paper>
    );
};

/**
 * @public
 */
export const Route = createFileRoute("/_auth/organization/groups/$groupId")({
    loader: ({ params: { groupId } }) => {
        queryClient.ensureQueryData(groupsQueryOptions.rootGroup).catch(logger.error);
        queryClient.ensureQueryData(groupsQueryOptions.groupDetails(groupId)).catch(logger.error);
    },
    component: GroupDetailsContainer,
});
