import { Input, InputWrapperProps } from "@mantine/core";
// @ts-ignore
import IntlTelInput from "intl-tel-input/reactWithUtils";

export const PhoneNumber = ({
    value = "",
    onClearError,
    onSetError,
    onSetValue,
    error,
    ...rest
}: InputWrapperProps & {
    value?: string;
    onSetError?: (err: string) => unknown;
    onClearError?: () => unknown;
    onSetValue?: (v: string) => unknown;
}) => {
    return (
        <Input.Wrapper error={error} {...rest}>
            <br />
            <IntlTelInput
                initialValue={value as string}
                initOptions={{ initialCountry: "US" }}
                onChangeValidity={(valid: boolean) => {
                    if (valid) onClearError?.();
                    else onSetError?.("Invalid phone number");
                }}
                onChangeNumber={(n: string) => {
                    if (n != value) {
                        onSetValue?.(n);
                    }
                }}
            />
        </Input.Wrapper>
    );
};
